import { axiosBaseQuery } from './base.service';
import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import { LeoAnalyzeHistory } from '../interfaces/leo-analyze-history';
import {
  HttpBaseRequest,
  LeoAnalysis,
  LeoAnalysisReactionRequest,
  LeoAnalysisReactionResponse,
  LeoAnalysisSuggestionReaction,
  LeoDashboardAnalysisRequest,
  LeoFlowAnalysisRequest,
} from '@vision/ui/interfaces';

export const LeoService = createApi({
  reducerPath: 'LeoService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    analyzeFlow: builder.mutation<{ analysis: LeoAnalysis }, HttpBaseRequest<LeoFlowAnalysisRequest>>({
      query: ({ flowId, purpose, accountId }) => ({
        url: `/v1/leo/flow_analysis/${flowId}?account_id=${accountId}`,
        method: HttpMethod.POST,
        data: { purpose },
        extraOptions: {
          notification: {
            autoHandleSuccess: false,
          },
        },
      }),
    }),
    analyzeDashboard: builder.mutation<{ analysis: LeoAnalysis }, HttpBaseRequest<LeoDashboardAnalysisRequest>>({
      query: ({ dashboardId, purpose, widget_ids, accountId, dashboardAnalysisType }) => ({
        url: `/v1/leo/dashboard_${dashboardAnalysisType}_analysis/${dashboardId}?account_id=${accountId}`,
        method: HttpMethod.POST,
        data: { purpose, widget_ids },
        extraOptions: {
          notification: {
            autoHandleSuccess: false,
          },
        },
      }),
    }),
    flowAnalyzeHistory: builder.query<LeoAnalyzeHistory[], void>({
      query: () => ({
        url: `/v1/leo/flows_with_histories`,
        method: HttpMethod.GET,
      }),
    }),
    dashboardAnalyzeHistory: builder.query<LeoAnalyzeHistory[], void>({
      query: () => ({
        url: `/v1/leo/dashboards_with_histories`,
        method: HttpMethod.GET,
      }),
    }),
    quota: builder.query<{ quota: number }, { accountId: string }>({
      query: ({ accountId }) => ({
        url: `/v1/leo/remaining_quota/${accountId}`,
        method: HttpMethod.GET,
      }),
    }),
    favorite: builder.mutation<LeoAnalysis, { analysisId: string }>({
      query: ({ analysisId }) => ({
        url: `/v1/leo/favourite_leo_history/${analysisId}`,
        method: HttpMethod.POST,
        extraOptions: {
          notification: {
            autoHandleSuccess: false,
          },
        },
      }),
    }),
    unfavorite: builder.mutation<LeoAnalysis, { analysisId: string }>({
      query: ({ analysisId }) => ({
        url: `/v1/leo/unfavourite_leo_history/${analysisId}`,
        method: HttpMethod.POST,
        extraOptions: {
          notification: {
            autoHandleSuccess: false,
          },
        },
      }),
    }),
    createReaction: builder.mutation<LeoAnalysisReactionResponse, LeoAnalysisReactionRequest>({
      query: ({ analysisId, reaction }) => ({
        url: `/v1/leo/leo_history_reaction/${analysisId}?reaction=${reaction}`,
        method: HttpMethod.POST,
        extraOptions: {
          notification: {
            autoHandleSuccess: false,
          },
        },
      }),
    }),
    destroyReaction: builder.mutation<LeoAnalysisReactionResponse, { analysisId: string }>({
      query: ({ analysisId }) => ({
        url: `/v1/leo/leo_history_reaction/${analysisId}/destroy`,
        method: HttpMethod.POST,
        extraOptions: {
          notification: {
            autoHandleSuccess: false,
          },
        },
      }),
    }),
    setSuggestionReaction: builder.mutation<LeoAnalysisSuggestionReaction, { suggestionId: string; reaction: string }>({
      query: ({ suggestionId, reaction }) => ({
        url: `/v1/leo/leo_comment_reaction/${suggestionId}?reaction=${reaction}`,
        method: HttpMethod.POST,
        extraOptions: {
          notification: {
            autoHandleSuccess: false,
          },
        },
      }),
    }),
    destroySuggestionReaction: builder.mutation<void, { reactionId: string }>({
      query: ({ reactionId }) => ({
        url: `/v1/leo/leo_comment_reaction/${reactionId}/destroy`,
        method: HttpMethod.POST,
        extraOptions: {
          notification: {
            autoHandleSuccess: false,
          },
        },
      }),
    }),
    requestQuota: builder.mutation<void, void>({
      query: () => ({
        url: `/v1/leo/send_quota_request`,
        method: HttpMethod.POST,
        extraOptions: {
          notification: {
            translationMessageKey: 'http.message.leo.requestQuota.{{status}}',
          },
        },
      }),
    }),
  }),
});

export const {
  useAnalyzeDashboardMutation,
  useAnalyzeFlowMutation,
  useCreateReactionMutation,
  useDestroyReactionMutation,
  useDestroySuggestionReactionMutation,
  useFavoriteMutation,
  useLazyDashboardAnalyzeHistoryQuery,
  useLazyFlowAnalyzeHistoryQuery,
  useLazyQuotaQuery,
  useRequestQuotaMutation,
  useSetSuggestionReactionMutation,
  useUnfavoriteMutation,
} = LeoService;
