import { If, VLazyLoadMultiSelect, VSelectOptionWithDescription } from '@vision/ui/components';
import { useEmojiContext, useSelectedAccountId } from '@vision/ui/hooks';
import { QuestionStyles, ReportsDetailGraphicSchemaValues, SimpleFlowQuestion } from '@vision/ui/interfaces';
import {
  useLazyFetchFlowQuestionsWithPaginationQuery,
  useLazyFetchFlowsWithPaginationQuery,
} from '@vision/ui/services';
import { getBodyText, translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

const RESPONSE_QUESTION_TYPES: QuestionStyles[] = ['text', 'textarea', 'customer_schema'];

interface ReportsDetailGraphicCompareQuestionResponseProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareQuestionResponse({
  compareItemType,
  required,
}: ReportsDetailGraphicCompareQuestionResponseProps) {
  const { i18n, t } = useTranslation(['translation', 'page-reports']);
  const { textWithNativeEmoji } = useEmojiContext();

  const accountId = useSelectedAccountId();

  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();
  const isFlowSelected = formik.values.selectedCompareCriteria.selectedQuestionResponseFlowIds.length > 0;

  const handleDefaultValuesLoaded = (data: SimpleFlowQuestion[]) => {
    // data içindeki idleri formik.values.selectedCompareCriteria.selectedQuestionIds içinde varsa onu sil
    const newIds = formik.values.selectedCompareCriteria.selectedQuestionResponseQuestionIds.filter((id) =>
      data.some((item) => item.id === id),
    );
    formik.setFieldValue('selectedCompareCriteria.selectedQuestionResponseQuestionIds', newIds);
  };

  return (
    <ReportsDetailGraphicCompareLayout title={t(`page-reports:reportCompareGroupItemType.${compareItemType}`)}>
      <VLazyLoadMultiSelect
        id="report-compare-question-response-flow"
        data-testid="input-report-compare-question-response-flow"
        label={t('flows')}
        useLazyApiQueryFunction={useLazyFetchFlowsWithPaginationQuery}
        apiRequestParameters={{
          nodeId: accountId,
          includeQuestions: true,
          questionStyles: RESPONSE_QUESTION_TYPES,
          sortBy: 'name',
          sortOrder: 'asc',
        }}
        multiSelectItemMapper={(items) => {
          return items.map((item) => ({
            value: item.id,
            label: item.name,
          }));
        }}
        searchKey="flow_name"
        value={formik.values.selectedCompareCriteria.selectedQuestionResponseFlowIds}
        onChange={(values) => formik.setFieldValue('selectedCompareCriteria.selectedQuestionResponseFlowIds', values)}
        onBlur={formik.handleBlur}
        error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedQuestionResponseFlowIds')}
        withAsterisk={required}
        itemComponent={VSelectOptionWithDescription}
      />

      <If value={isFlowSelected}>
        <VLazyLoadMultiSelect
          id="report-compare-flow-question"
          data-testid="input-report-compare-flow-question"
          label={t('question')}
          useLazyApiQueryFunction={useLazyFetchFlowQuestionsWithPaginationQuery}
          apiRequestParameters={{
            nodeId: accountId,
            flowIds: formik.values.selectedCompareCriteria.selectedQuestionResponseFlowIds,
            style: RESPONSE_QUESTION_TYPES,
            sortBy: 'name',
            sortOrder: 'asc',
          }}
          multiSelectItemMapper={(items) => {
            return items
              .filter((item) => item.body !== null)
              .map((item) => ({
                value: item.id,
                label: textWithNativeEmoji(getBodyText(item.body, i18n.language)),
                description: item?.flow_name,
              }));
          }}
          searchKey="question_body"
          searchable={true}
          value={formik.values.selectedCompareCriteria.selectedQuestionResponseQuestionIds}
          onBlur={formik.handleBlur}
          onChange={(e) => formik.setFieldValue('selectedCompareCriteria.selectedQuestionResponseQuestionIds', e)}
          error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedQuestionResponseQuestionIds')}
          withAsterisk={required}
          resetChangeRequestParameters={true}
          onDefaultValuesLoaded={handleDefaultValuesLoaded}
          itemComponent={VSelectOptionWithDescription}
        />
      </If>
    </ReportsDetailGraphicCompareLayout>
  );
}
