import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import {
  ApiKeyResponse,
  ApiResponse,
  HttpBaseRequest,
  User,
  UserInviteRequest,
  UserList,
  UserPaginationParams,
  UserUpdateRequest,
} from '@vision/ui/interfaces';
import {
  buildEndpointWithQuery,
  createHttpExtraOptions,
  insertIfObject,
  sortByNameAlphabetical,
} from '@vision/ui/utils';
import { axiosBaseQuery } from './base.service';

export const UsersService = createApi({
  reducerPath: 'UsersService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getUsers: builder.query<User[], HttpBaseRequest<{ role?: string; nodeId?: string }>>({
      query: ({ role, nodeId }) => ({
        url: '/v1/users',
        method: HttpMethod.GET,
        params: {
          role,
          ...insertIfObject(!!nodeId, {
            node_id: nodeId,
          }),
        },
      }),
      transformResponse: (response: User[]) => {
        return sortByNameAlphabetical(response);
      },
    }),
    updateUser: builder.mutation<UserUpdateRequest, UserUpdateRequest>({
      query: (user) => ({
        url: `/v1/users/${user.id}/update`,
        method: HttpMethod.POST,
        data: user,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoClose: 1000,
            translationMessageKey: 'http.message.profile.{{status}}',
          },
        }),
      }),
    }),
    inviteUser: builder.mutation<User, UserInviteRequest>({
      query: (user) => ({
        url: `/v1/users/invite`,
        method: HttpMethod.POST,
        data: user,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.inviteUser.{{status}}',
          },
        }),
      }),
    }),
    sendActivationMail: builder.mutation<void, string>({
      query: (userId) => ({
        url: `/v1/send_activation_mail`,
        method: HttpMethod.POST,
        data: { id: userId },
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.sendActivationMail.{{status}}',
          },
        }),
      }),
    }),
    users: builder.query<ApiResponse<UserList[]>, HttpBaseRequest<{ query: string }>>({
      query: ({ query }) => ({
        url: buildEndpointWithQuery('/v1/users', query),
        method: HttpMethod.GET,
        params: {
          hide_uber_admin: true,
        },
      }),
    }),
    getUserDetail: builder.query<User, HttpBaseRequest<{ userId: string }>>({
      query: ({ userId }) => ({
        url: `/v1/users/${userId}`,
        method: HttpMethod.GET,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.profile.{{status}}',
          },
        }),
      }),
    }),
    getUserApiKey: builder.query<ApiKeyResponse, HttpBaseRequest<{ userId: string }>>({
      query: ({ userId }) => ({
        url: `/v1/users/${userId}/api_key`,
        method: HttpMethod.GET,
      }),
    }),
    createApiKey: builder.mutation<ApiKeyResponse, HttpBaseRequest<{ userId: string }>>({
      query: ({ userId }) => ({
        url: `/v1/users/${userId}/api_keys`,
        method: HttpMethod.POST,
      }),
    }),
    deleteApiKey: builder.mutation<void, { userId: string; id: string }>({
      query: ({ userId, id }) => ({
        url: `/v1/users/${userId}/api_keys/${id}/destroy`,
        method: HttpMethod.POST,
      }),
    }),
    fetchUsersWithPagination: builder.query<ApiResponse<User[]>, UserPaginationParams>({
      query: ({ nodeId, view, query, status, sortBy, sortOrder }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${nodeId}/dashboards/users`, query),
        method: HttpMethod.GET,
        params: {
          ...insertIfObject(!!view, {
            view,
          }),
          ...insertIfObject(!!status, {
            status,
          }),
          ...insertIfObject(!!sortBy, {
            sort_by: sortBy,
          }),
          ...insertIfObject(!!sortOrder, {
            sort_order: sortOrder,
          }),
        },
      }),
    }),
  }),
});

export const {
  useLazyGetUsersQuery,
  useGetUsersQuery,
  useInviteUserMutation,
  useSendActivationMailMutation,
  useUpdateUserMutation,
  useLazyUsersQuery,
  useGetUserDetailQuery,
  useGetUserApiKeyQuery,
  useCreateApiKeyMutation,
  useDeleteApiKeyMutation,
  useLazyFetchUsersWithPaginationQuery,
} = UsersService;
