import { createApi } from '@reduxjs/toolkit/query/react';
import { Dashboard, DashboardWidget, HttpBaseRequest } from '@vision/ui/interfaces';
import { DashboardType, HttpMethod } from '@vision/ui/enums';
import { axiosBaseQuery } from './base.service';

export const DashboardsService = createApi({
  reducerPath: 'DashboardsService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    dashboards: builder.query<Dashboard[], HttpBaseRequest<{ nodeId: string; type: DashboardType }>>({
      query: ({ nodeId, type = DashboardType.STANDARD }) => ({
        url: '/v1/dashboards',
        method: HttpMethod.GET,
        params: {
          node_id: nodeId,
          type,
        },
      }),
    }),
    dashboardWidgets: builder.query<DashboardWidget[], HttpBaseRequest<{ dashboardId: string }>>({
      query: ({ dashboardId }) => ({
        url: `/v1/dashboard_widgets?dashboard_id=${dashboardId}`,
        method: HttpMethod.GET,
      }),
    }),
  }),
});

export const { useLazyDashboardsQuery, useLazyDashboardWidgetsQuery } = DashboardsService;
