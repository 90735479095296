import { ReportChartConfiguration } from '@vision/ui/interfaces';
import { object, string } from 'yup';
import { DefaultReportChartThemeFont } from '../default-chart.config';

export const WordCloudChart1DefaultConfiguration: ReportChartConfiguration = {
  validationSchema: object().shape({
    selectedMeasure: string().required('validation:rules.generic.required'),
  }),
  measures: null,
  defaultMeasure: 'kda-count',
  compareRendererComponent: null,
  hideCompare: true,
  directApiCall: true,
  defaultCustomizeSection: 'customize',
  valueComponentHide: true,
  customize: {
    active: (values) => !!values.selectedMeasure,
    transactionalDate: {
      active: false,
    },
    kdaLanguage: 'original',
    limits: {
      upperLimitActive: false,
      upperLimit: 0,
      lowerLimitActive: false,
      lowerLimit: 0,
    },
  },
  defaultTheme: {
    active: (values) => !!values.selectedMeasure,
    title: {
      ...DefaultReportChartThemeFont,
      label: '',
    },
    wordCloud: {
      active: false,
      negative: {
        ...DefaultReportChartThemeFont,
        fontColor: '#FFC1C1',
        fontSize: 18,
      },
      positive: {
        ...DefaultReportChartThemeFont,
        fontColor: '#A0FCE5',
        fontSize: 18,
      },
      neutral: {
        ...DefaultReportChartThemeFont,
        fontColor: '#B2D5FF',
        fontSize: 18,
      },
      maxNumberOfWords: 10,
    },
  },
};
