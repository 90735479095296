import { ToolboxComponentOption } from 'echarts';
import { EC, EventHandlerProps } from '../../ECharts.types';
import { componentLoader, optionCreator } from '../../ECharts.utils';

export type ToolboxProps = EventHandlerProps & ToolboxComponentOption;
/**
 * @example
 * ```tsx
 * <Toolbox feature={{ saveAsImage: {} }} />
 * ```
 */
export const Toolbox: EC<ToolboxProps> = () => <></>;

Toolbox.optionOf = optionCreator('toolbox');

Toolbox.loadModule = componentLoader(['ToolboxComponent']);
