import { faAngleDown, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Badge, Button, Group, ScrollArea, useMantineTheme } from '@mantine/core';
import { VDrawer } from '@vision/ui/components';
import { useDataGridContext } from '@vision/ui/context';
import { FilterType } from '@vision/ui/enums';
import { Filter, FilterSelections } from '@vision/ui/interfaces';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from 'react-use';
import { If } from '../../../If';
import { TooltipCard } from '../../../TooltipCard';
import { DataGridFilterRenderer } from '../DataGridFilterControls';
import { DataGridFilterSelectionTooltipLabel } from '../DataGridFilterSelectionTooltipLabel';

interface DataGridFilterSelectedOptionCountProps {
  children: (count: number) => JSX.Element;
  filter: Filter;
  filterSelections: FilterSelections[];
}

function _DataGridFilterSelectedOptionCount({
  children,
  filter,
  filterSelections,
}: DataGridFilterSelectedOptionCountProps) {
  const count = useMemo(() => {
    const selection = filterSelections.find((item) => item.key === filter.key);

    if (!selection) {
      return 0;
    }

    switch (filter.type) {
      case FilterType.DATEPICKER:
      case FilterType.RADIO_DATEPICKER:
      case FilterType.RADIO:
      case FilterType.TEXT:
        return 1;
      case FilterType.CHECKBOX:
        return (selection.value as string[]).length;
      default:
        return 0;
    }
  }, [filter, filterSelections]);

  return children(count);
}

const DataGridFilterSelectedOptionCount = React.memo(_DataGridFilterSelectedOptionCount);

interface DataGridFilterDrawerProps {
  filterSelections: FilterSelections[];
  onClose: VoidFunction;
  onFilterSelectionChange: (selections: FilterSelections[]) => void;
  opened: boolean;
}

function _DataGridFilterDrawer({
  filterSelections,
  onClose,
  onFilterSelectionChange,
  opened,
}: DataGridFilterDrawerProps) {
  const {
    meta: { filters },
  } = useDataGridContext();
  const { t } = useTranslation(['translation', 'data-grid']);
  const theme = useMantineTheme();
  const [temporaryFilterSelections, setTemporaryFilterSelections] = useState(() => [...filterSelections]);
  const filterableFilters = useMemo<Filter[]>(() => {
    return filters.filter((item) => item.filterable);
  }, [filters]);

  const [activeAccordionValue, setActiveAccordionValue] = useState<string>(filterableFilters[0]?.key);

  const selectedFilters = useMemo<Filter[]>(() => {
    return filterableFilters
      .filter((item) => item.filterable)
      .filter((item) => temporaryFilterSelections.some((selectedFilter) => selectedFilter.key === item.key));
  }, [filterableFilters, temporaryFilterSelections]);

  const handleClearAll = () => {
    setTemporaryFilterSelections([]);
  };

  const handleSave = () => {
    onFilterSelectionChange(temporaryFilterSelections);
    onClose();
  };

  const handleRemoveFilter = (filter: Filter) => {
    setTemporaryFilterSelections(temporaryFilterSelections.filter((item) => filter.key !== item.key));
  };

  useDeepCompareEffect(() => {
    setTemporaryFilterSelections([...filterSelections]);
  }, [filterSelections]);

  return (
    <VDrawer opened={opened} onClose={handleSave} title={t('data-grid:addFilter')}>
      <VDrawer.Body>
        <If value={selectedFilters.length > 0}>
          <Group py={10} px={30} gap={10}>
            {selectedFilters.map((item) => (
              <TooltipCard position="top-start" key={item.key}>
                <TooltipCard.Target>
                  <Button
                    data-testid={`button-selected-filter-${item.key}`}
                    variant="light"
                    radius="sm"
                    size="xs"
                    rightSection={<FontAwesomeIcon icon={faXmark} color={theme.colors.blue[9]} fontSize={14} />}
                    onClick={() => handleRemoveFilter(item)}
                  >
                    <Group gap={5}>
                      {item.label || t(`data-grid:columnKeys.${item.key}`)}
                      <DataGridFilterSelectedOptionCount filter={item} filterSelections={temporaryFilterSelections}>
                        {(count) => <span>({count})</span>}
                      </DataGridFilterSelectedOptionCount>
                    </Group>
                  </Button>
                </TooltipCard.Target>

                <TooltipCard.Dropdown>
                  <DataGridFilterSelectionTooltipLabel filter={item} filterSelections={temporaryFilterSelections} />
                </TooltipCard.Dropdown>
              </TooltipCard>
            ))}
          </Group>
        </If>

        <Accordion
          defaultValue={filterableFilters[0]?.key}
          value={activeAccordionValue}
          onChange={setActiveAccordionValue}
          chevron={<FontAwesomeIcon icon={faAngleDown} fontSize={16} color={theme.colors.dark[5]} />}
        >
          {filterableFilters.map((item) => (
            <Accordion.Item key={item.key} value={item.key}>
              <Accordion.Control data-testid={`button-accordion-filter-control-${item.key}`}>
                <Group justify="space-between">
                  {item.label}
                  <DataGridFilterSelectedOptionCount filter={item} filterSelections={temporaryFilterSelections}>
                    {(count) => {
                      if (count === 0) {
                        return null;
                      }

                      return (
                        <Badge radius="sm" variant="filled" w={20} h={20} p={0} fz={8}>
                          {count}
                        </Badge>
                      );
                    }}
                  </DataGridFilterSelectedOptionCount>
                </Group>
              </Accordion.Control>
              <Accordion.Panel data-testid={`section-accordion-filter-panel-${item.key}`}>
                <ScrollArea mah={250}>
                  <DataGridFilterRenderer
                    filter={item}
                    filterSelections={temporaryFilterSelections}
                    onFilterChange={setTemporaryFilterSelections}
                  />
                </ScrollArea>
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </VDrawer.Body>

      <VDrawer.Footer>
        <Button variant="subtle" color="gray" onClick={handleClearAll} data-testid="button-filter-clear-all">
          {t('clearAll')}
        </Button>
        <Button variant="filled" onClick={handleSave} data-testid="button-filter-save">
          {t('save')}
        </Button>
      </VDrawer.Footer>
    </VDrawer>
  );
}

export const DataGridFilterDrawer = React.memo(_DataGridFilterDrawer);
