import { ReportChartCompare, ReportChartFilter, ReportsDetailGraphicSchemaValues } from '@vision/ui/interfaces';
import { DEFAULT_REPORT_DATE_PICKER_VALUE } from './default-chart.config';

export const DEFAULT_REPORT_DETAIL_GRAPHIC_FILTER_SCHEMA_VALUES: ReportChartFilter = {
  selectedChannelIds: [],
  selectedChannelTypes: [],
  selectedAssignedUserIds: [],
  selectedFeedbackStatus: [],
  selectedFlowIds: [],
  selectedIntelligentTagIds: [],
  selectedLanguageIds: [],
  selectedQuestionIds: [],
  selectedQuestionOptionIds: [],
  selectedTagGroupIds: [],
  selectedTagIds: [],
  selectedUnitTypeIds: [],
  selectedUnitIds: [],
  hasComments: null,
  hasContactInfo: null,
  spamFeedback: null,
  onlyNegativeFeedback: null,
  includeIncompleteFeedback: false,
  selectedContentCategoryIds: [],
  selectedEmployeeStageTitles: [],
  selectedDepartmentIds: [],
};

export const DEFAULT_REPORT_DETAIL_GRAPHIC_COMPARE_SCHEMA_VALUES: ReportChartCompare = {
  selectedChannelIds: [],
  selectedChannelTypes: [],
  selectedCompanyIds: [],
  selectedFlowIds: [],
  selectedIntelligentTagIds: [],
  selectedQuestionFlowIds: [],
  selectedQuestionIds: [],
  selectedQuestionOptionFlowIds: [],
  selectedQuestionOptionQuestionIds: [],
  selectedQuestionOptionIds: [],
  selectedQuestionResponseFlowIds: [],
  selectedQuestionResponseQuestionIds: [],
  selectedQuestionKeyFlowIds: [],
  selectedQuestionKeyIds: [],
  selectedQuestionCategoryFlowIds: [],
  selectedQuestionCategoryIds: [],
  selectedSchemaCodes: [],
  selectedTagGroupIds: [],
  selectedTagTagGroupIds: [],
  selectedTagGroupNameIds: [],
  selectedUnitIds: [],
  selectedUnitTypeIds: [],
  selectedUserIds: [],
  selectedUserAssignedIds: [],
  selectedUserArchivingIds: [],
  selectedUserResponsibleIds: [],
  selectedContentCategoryIds: [],
  selectedFeedbackStatus: [],
};

export const DEFAULT_REPORT_DETAIL_GRAPHIC_SCHEMA_VALUES: ReportsDetailGraphicSchemaValues = {
  selectedDate: DEFAULT_REPORT_DATE_PICKER_VALUE,
  selectedTheme: {},
  selectedCustomize: {},
  selectedFilterKeys: [],
  selectedFilters: {
    ...DEFAULT_REPORT_DETAIL_GRAPHIC_FILTER_SCHEMA_VALUES,
  },
  selectedMeasure: null,
  selectedMultipleMeasure: [],
  selectedCompare: [],
  selectedCompareCriteria: {
    ...DEFAULT_REPORT_DETAIL_GRAPHIC_COMPARE_SCHEMA_VALUES,
  },
  selectedPeriodAndValueComparison: [],
};
