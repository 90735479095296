import { ReportChartConfiguration } from '@vision/ui/interfaces';
import { t } from 'i18next';
import { array, object, string } from 'yup';
import { ScatterChart1CompareRenderer, ScatterChart1ValueComponent } from '../../components';
import {
  DefaultReportChartCompareSchema,
  DefaultReportChartThemeFont,
  DefaultReportChartThemeSeries,
  GRAPHIC_TITLE_MAX_CHAR_COUNT,
} from '../default-chart.config';

export const ScatterChart1DefaultConfiguration: ReportChartConfiguration = {
  validationSchema: object().shape({
    selectedDate: object().shape({
      fromDate: string().required('validation:rules.generic.required'),
      toDate: string().required('validation:rules.generic.required'),
      dateRange: string().optional(),
    }),
    selectedMultipleMeasure: array().of(string()).min(2, 'validation:rules.generic.required'),
    selectedTheme: object().shape({
      title: object().shape({
        label: string()
          .max(
            GRAPHIC_TITLE_MAX_CHAR_COUNT,
            t('validation:rules.generic.max', {
              count: GRAPHIC_TITLE_MAX_CHAR_COUNT,
            }),
          )
          .required('validation:rules.reports.selectedTheme.title.label.required'),
      }),
    }),
    selectedCompareCriteria: object().shape({
      ...DefaultReportChartCompareSchema,
    }),
    selectedCompare: array()
      .of(string().required('validation:rules.generic.required'))
      .min(1, 'validation:rules.generic.required'),
  }),
  measures: null,
  compareRendererComponent: ScatterChart1CompareRenderer,
  responseMultiple: true,
  hideCompare: true,
  chartValueComponent: ScatterChart1ValueComponent,
  customize: {
    active: (values) =>
      values.selectedMultipleMeasure && values.selectedMultipleMeasure.length > 0 && values.selectedCompare.length > 0,
    transactionalDate: {
      active: false,
    },
    limits: {
      upperLimitActive: false,
      upperLimit: 0,
      lowerLimitActive: false,
      lowerLimit: 0,
    },
  },
  defaultTheme: {
    active: (values) =>
      values.selectedMultipleMeasure && values.selectedMultipleMeasure.length > 0 && values.selectedCompare.length > 0,
    title: {
      ...DefaultReportChartThemeFont,
      label: '',
    },
    xAxis: {
      ...DefaultReportChartThemeFont,
    },
    yAxis: {
      ...DefaultReportChartThemeFont,
      activeDisplayFormat: true,
      displayFormat: 'auto',
    },
    series: {
      ...DefaultReportChartThemeSeries,
    },
  },
};
