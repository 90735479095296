import { If, VLazyLoadMultiSelect, VMultiSelect } from '@vision/ui/components';
import { useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues, TagGroup } from '@vision/ui/interfaces';
import { useLazyGetTagGroupsQuery } from '@vision/ui/services';
import { groupTagGroupsByName, translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

interface ReportsDetailGraphicCompareTagNameProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareTagName({
  compareItemType,
  required,
}: ReportsDetailGraphicCompareTagNameProps) {
  const { t } = useTranslation();
  const accountId = useSelectedAccountId();
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();

  const [tagGroups, setTagGroups] = useState<TagGroup[]>([]);

  const tagGroupNameSelectItems = useMemo(() => {
    if (!formik.values.selectedCompareCriteria.selectedTagTagGroupIds.length) {
      return [];
    }

    const selectedTagGroups = formik.values.selectedCompareCriteria.selectedTagTagGroupIds
      .map((id) => tagGroups.find((group) => group.id === id))
      .filter(Boolean);
    return groupTagGroupsByName(selectedTagGroups);
  }, [tagGroups, formik.values.selectedCompareCriteria.selectedTagTagGroupIds]);

  const handleTagGroupChange = (selectedTagGroupValue: string[]) => {
    const selectedTagGroups = selectedTagGroupValue.map((id) => tagGroups.find((group) => group.id === id));

    const newSelectedTagGroupNameIds = formik.values.selectedCompareCriteria.selectedTagGroupNameIds.filter((id) =>
      selectedTagGroups.some((group) => group?.tags?.some((tag) => tag.id === id)),
    );

    formik.setValues({
      ...formik.values,
      selectedCompareCriteria: {
        ...formik.values.selectedCompareCriteria,
        selectedTagTagGroupIds: selectedTagGroupValue,
        selectedTagGroupNameIds: newSelectedTagGroupNameIds,
      },
    });
  };

  return (
    <ReportsDetailGraphicCompareLayout title={t(`page-reports:reportCompareGroupItemType.${compareItemType}`)}>
      <VLazyLoadMultiSelect
        id="report-compare-tag-group"
        data-testid="input-report-compare-tag-group"
        label={t('tagGroup')}
        filterKey="tag_group_ids"
        useLazyApiQueryFunction={useLazyGetTagGroupsQuery}
        apiRequestParameters={{ nodeId: accountId, sortBy: 'name', sortOrder: 'asc' }}
        multiSelectItemMapper={(items) => {
          return items.map((item) => ({
            value: item.id,
            label: item.name,
          }));
        }}
        searchKey="tag_group_name"
        value={formik.values.selectedCompareCriteria.selectedTagTagGroupIds}
        onDataChange={setTagGroups}
        onChange={handleTagGroupChange}
        error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedTagTagGroupIds')}
        withAsterisk={required}
      />

      <If value={formik.values.selectedCompareCriteria.selectedTagTagGroupIds.length > 0}>
        <VMultiSelect
          id="report-compare-tag-group-name"
          data-testid="input-report-compare-tag-group-name"
          label={t('tagName')}
          data={tagGroupNameSelectItems}
          value={formik.values.selectedCompareCriteria.selectedTagGroupNameIds}
          searchable={true}
          onChange={(e) => formik.setFieldValue('selectedCompareCriteria.selectedTagGroupNameIds', e)}
          error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedTagGroupNameIds')}
          withAsterisk={required}
        />
      </If>
    </ReportsDetailGraphicCompareLayout>
  );
}
