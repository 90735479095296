import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import {
  ApiResponse,
  ApiResponseSimple,
  ReportChartJobIdResponse,
  ReportDetail,
  ReportDetailFilterOptions,
  ReportFeedbackRequest,
  ReportFeedbackResponseCollection,
  ReportFeedbackStaticResponse,
  ReportWidget,
  ReportWidgetLayout,
  ReportWidgetLayoutRequest,
} from '@vision/ui/interfaces';
import { reportCalculateDateRange, ReportChartMeasureApiPathType } from '../pages/ReportsDetailGraphic/constants';
import { createHttpExtraOptions, insertIfObject } from '../utils';
import { axiosBaseQuery } from './base.service';
import { ReportsService } from './reports.service';

// ReportsDetailService diye değiştirilebilir.
export const ReportWidgetsService = createApi({
  reducerPath: 'ReportWidgetsService',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['GetReportWidgets'],
  endpoints: (builder) => ({
    getReportWidgets: builder.query<ApiResponse<ReportWidget[]>, { nodeId?: string; reportId: string }>({
      query: ({ nodeId, reportId }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/${reportId}/dashboard_widgets/list`,
        method: HttpMethod.GET,
      }),
      transformResponse: (response: ApiResponse<ReportWidget[]>) => {
        const { data: widgets } = response;

        const dateRangeWidgets = widgets.filter((widget) => !!widget?.detail?.date_range);

        const updatedWidgets = widgets.map((widget) => {
          if (dateRangeWidgets.includes(widget)) {
            const { fromDate, toDate } = reportCalculateDateRange(widget.detail.date_range);
            return {
              ...widget,
              detail: { ...widget.detail, from_date: fromDate, to_date: toDate },
              must_be_update: fromDate !== widget.detail.from_date || toDate !== widget.detail.to_date,
            };
          }
          return widget;
        });
        return {
          ...response,
          data: updatedWidgets,
        };
      },
      async onQueryStarted(result, { dispatch, queryFulfilled }) {
        const { data: widgets } = await queryFulfilled;

        // date_range olan widget'ları bul
        const dateRangeWidgets = widgets.data.filter((widget) => !!widget?.must_be_update);

        // Her bir date_range widget'ı için güncelleme yap
        for (const widget of dateRangeWidgets) {
          try {
            await dispatch(
              ReportWidgetsService.endpoints.updateReportWidget.initiate({
                nodeId: result.nodeId,
                reportId: result.reportId,
                widgetId: widget.id,
                data: widget,
              }),
            ).unwrap();
          } catch (error) {
            console.error(`Failed to update widget ${widget.id}:`, error);
          }
        }
      },
      providesTags: ['GetReportWidgets'],
    }),
    deleteReportWidget: builder.mutation<
      ApiResponse<ReportDetail>,
      { nodeId: string; reportId: string; widgetId: string }
    >({
      query: ({ nodeId, reportId, widgetId }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/${reportId}/dashboard_widgets/${widgetId}/delete`,
        method: HttpMethod.POST,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.reports.deleteChart.{{status}}',
          },
        }),
      }),
      async onQueryStarted(result, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(ReportsService.util.invalidateTags(['GetFavoriteReports']));
      },
    }),
    getReport: builder.query<ApiResponse<ReportDetail>, { nodeId: string; reportId: string }>({
      query: ({ nodeId, reportId }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/${reportId}`,
        method: HttpMethod.GET,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleError: false,
          },
        }),
      }),
      transformResponse: (response: ApiResponse<ReportDetail>) => {
        const { data } = response;
        if (data?.detail?.date_range) {
          const { fromDate, toDate } = reportCalculateDateRange(data?.detail?.date_range);
          return {
            ...response,
            data: {
              ...data,
              detail: {
                ...data.detail,
                from_date: fromDate,
                to_date: toDate,
              },
              must_be_update: fromDate !== data.detail.from_date || toDate !== data.detail.to_date,
            },
          };
        }
        return response;
      },
      async onQueryStarted(result, { dispatch, queryFulfilled }) {
        const { data: reportDetail } = await queryFulfilled;
        if (reportDetail?.data?.must_be_update) {
          try {
            await dispatch(
              ReportWidgetsService.endpoints.updateReportDetailFilters.initiate({
                nodeId: result.nodeId,
                reportId: result.reportId,
                detail: reportDetail.data.detail,
              }),
            ).unwrap();
          } catch (error) {
            console.error(`Failed to update report ${result.reportId}:`, error);
          }
        }
      },
    }),
    updateReportDetail: builder.mutation<
      ApiResponse<ReportDetail>,
      {
        accountId: string;
        reportId: string;
        data: Partial<ReportDetail>;
        showSuccessNotification?: boolean;
        translationMessageKey?: string;
      }
    >({
      query: ({
        accountId,
        reportId,
        data,
        showSuccessNotification = false,
        translationMessageKey = 'http.message.reports.updateDetail.{{status}}',
      }) => ({
        url: `/v2/accounts/${accountId}/dashboards/${reportId}/update`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: showSuccessNotification,
            translationMessageKey: translationMessageKey,
          },
        }),
      }),
    }),
    getFeedbackOrJobId: builder.query<
      ReportChartJobIdResponse | ApiResponseSimple<ReportFeedbackResponseCollection>,
      { params: ReportFeedbackRequest; url: ReportChartMeasureApiPathType; widgetId?: string; async?: boolean }
    >({
      query: ({ params, url, widgetId, async = true }) => ({
        url: `/v2/${url}`,
        method: HttpMethod.GET,
        params: {
          ...params,
          ...insertIfObject(!!async, { async }),
          dashboard_widget_id: widgetId,
        },
      }),
    }),
    createFeedbackOrJobId: builder.mutation<
      ReportChartJobIdResponse | ApiResponseSimple<ReportFeedbackResponseCollection>,
      {
        params: ReportFeedbackRequest;
        url: ReportChartMeasureApiPathType;
        widgetId?: string;
        async?: boolean;
      }
    >({
      query: ({ params, url, widgetId, async }) => ({
        url: `/v2/reporting/${url}`,
        method: HttpMethod.POST,
        data: {
          ...params,
          ...insertIfObject(!!async, { async }),
          dashboard_widget_id: widgetId,
        },
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
          },
        }),
      }),
    }),
    getFeedbackStaticResult: builder.query<ReportFeedbackStaticResponse, { jobId: string }>({
      query: ({ jobId }) => ({
        url: `/v2/feedback/statistic_result/${jobId}`,
        method: HttpMethod.GET,
      }),
    }),
    updateReportWidget: builder.mutation<
      ApiResponse<ReportWidget>,
      { nodeId: string; reportId: string; data: ReportWidget; widgetId: string }
    >({
      query: ({ nodeId, reportId, data, widgetId }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/${reportId}/dashboard_widgets/${widgetId}/update`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
          },
        }),
      }),
      async onQueryStarted(result, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(ReportsService.util.invalidateTags(['GetReportGraphicDetail', 'GetFavoriteReports']));
      },
    }),
    updateReportDetailFilters: builder.mutation<
      ApiResponse<ReportDetail>,
      { nodeId: string; reportId: string; detail: ReportDetailFilterOptions }
    >({
      query: ({ nodeId, reportId, detail }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/${reportId}/update`,
        method: HttpMethod.POST,
        data: {
          detail,
        },
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
          },
        }),
      }),
    }),
    updateReportWidgetsLayout: builder.mutation<
      ApiResponse<ReportWidgetLayoutRequest[]>,
      { accountId: string; reportId: string; data: ReportWidgetLayoutRequest[] }
    >({
      query: ({ accountId, reportId, data }) => ({
        url: `/v2/accounts/${accountId}/dashboards/${reportId}/dashboard_widgets/update_layouts`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
          },
        }),
      }),
    }),
    copyReportWidget: builder.mutation<
      ApiResponse<ReportWidget>,
      { nodeId: string; reportId: string; name: string; widgetId: string; layout: ReportWidgetLayout }
    >({
      query: ({ nodeId, reportId, name, widgetId, layout }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/${reportId}/dashboard_widgets/${widgetId}/duplicate`,
        method: HttpMethod.POST,
        data: {
          name,
          layout,
        },
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.reports.copyChart.{{status}}',
            autoHandleSuccess: false,
          },
        }),
      }),
    }),
  }),
});

export const {
  useLazyGetReportWidgetsQuery,
  useDeleteReportWidgetMutation,
  useGetReportQuery,
  useLazyGetFeedbackOrJobIdQuery,
  useCreateFeedbackOrJobIdMutation,
  useGetFeedbackStaticResultQuery,
  useUpdateReportWidgetMutation,
  useUpdateReportDetailMutation,
  useUpdateReportDetailFiltersMutation,
  useUpdateReportWidgetsLayoutMutation,
  useCopyReportWidgetMutation,
} = ReportWidgetsService;
