import { ButtonProps, Modal } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoPanel } from '../InfoPanel';
import { ModalFooter } from '../ModalFooter';
import classes from './Prompt.module.scss';

export interface PromptProps {
  cancelBtnProps?: Omit<ButtonProps, 'data-testid'>;
  cancelBtnLabel?: string;
  content: string;
  dataTestId?: string;
  heading: string;
  icon?: React.ReactNode;
  onCancel?: VoidFunction;
  onConfirm: VoidFunction;
  opened?: boolean;
  saveBtnProps?: Omit<ButtonProps, 'data-testid'>;
  title?: string;
  saveBtnLabel?: string;
}

export function Prompt({
  cancelBtnLabel,
  cancelBtnProps,
  content,
  dataTestId,
  heading,
  icon,
  onCancel,
  onConfirm,
  opened: isOpened,
  saveBtnLabel,
  saveBtnProps,
  title,
}: PromptProps) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(isOpened ?? false);

  const handleCancel = () => {
    setOpened(false);
    onCancel?.();
  };

  const handleConfirm = () => {
    setOpened(false);
    onConfirm();
  };

  useEffect(() => {
    setOpened(isOpened);
  }, [isOpened]);

  return (
    <Modal
      centered={true}
      className={classes.prompt}
      data-testid={dataTestId || 'section-prompt'}
      onClose={handleCancel}
      opened={opened}
      title={heading}
    >
      <InfoPanel content={content} title={title} icon={icon} />
      <ModalFooter
        cancelBtnLabel={cancelBtnLabel || t('cancel')}
        cancelBtnProps={cancelBtnProps}
        cancelBtnVisible={!!(onCancel || cancelBtnProps)}
        onCancel={handleCancel}
        onSave={handleConfirm}
        saveBtnLabel={saveBtnLabel || t('confirm')}
        saveBtnProps={saveBtnProps}
      />
    </Modal>
  );
}
