import { faArrowDownLong } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComboboxParsedItemGroup, Stack } from '@mantine/core';
import { VSelect } from '@vision/ui/components';
import { useAppSelector, useReportCombinationMulti } from '@vision/ui/hooks';
import {
  ReportChartGroupCompare,
  ReportChartGroupCompareConfig,
  ReportsDetailGraphicSchemaValues,
} from '@vision/ui/interfaces';
import { ScatterChart1Measures } from '@vision/ui/pages/ReportsDetailGraphic/constants/scatter-chart1';
import { convertToSelectGroupItems } from '@vision/ui/pages/ReportsDetailGraphic/utils';
import { selectedChartGroupItemSelector } from '@vision/ui/store';
import { ensureArray } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicConfigurationValueCompare } from '../../../ReportsDetailGraphicConfigurationValue';
import { ReportsDetailGraphicConfigurationValueHeader } from '../../../ReportsDetailGraphicConfigurationValueHeader';

export function ScatterChart1ValueComponent() {
  const { t } = useTranslation(['translation', 'page-reports']);
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();
  const selectedChartGroupItem = useAppSelector(selectedChartGroupItemSelector);

  const reportCombination: ReportChartGroupCompareConfig[] = useReportCombinationMulti(
    selectedChartGroupItem?.type,
    Array.from(new Set(ensureArray(formik.values.selectedMultipleMeasure))),
  );

  const compareSelectItems: ComboboxParsedItemGroup[] = useMemo(() => {
    if (!reportCombination) {
      return [];
    }

    const allCompareItems: ReportChartGroupCompare[] = reportCombination.reduce((acc, curr) => {
      return [...acc, ...curr.compareItems];
    }, []);

    // We group allCompareItems by type, combine the items in each group and return them.
    const allCompareItemsGrouped = allCompareItems.reduce((acc, curr) => {
      const existingGroup = acc.find((item) => item.type === curr.type);
      if (existingGroup) {
        // Combine the items in the group. but same items should not be duplicated.
        existingGroup.items = Array.from(new Set([...existingGroup.items, ...curr.items]));
      } else {
        acc.push({ type: curr.type, items: [...curr.items] });
      }
      return acc;
    }, [] as ReportChartGroupCompare[]);

    const commonItems = allCompareItemsGrouped.filter((item) => {
      const existAll = reportCombination.every((r) => r.compareItems.some((i) => i.type === item.type));
      return existAll;
    });

    return convertToSelectGroupItems(commonItems, 'reportCompareGroupType', 'reportCompareGroupItemType');
  }, [reportCombination]);

  const measurementSelectItems = useMemo(() => {
    if (!selectedChartGroupItem) {
      return [];
    }

    return convertToSelectGroupItems(
      ScatterChart1Measures,
      'reportMeasurementsGroupType',
      'reportMeasurementsGroupItemType',
    );
  }, [selectedChartGroupItem]);

  const handleOnMeasureChange = (value: string, index: number) => {
    formik.setFieldValue('selectedMultipleMeasure', [
      ...formik.values.selectedMultipleMeasure.slice(0, index),
      value,
      ...formik.values.selectedMultipleMeasure.slice(index + 1),
    ]);
  };

  return (
    <Stack gap={20}>
      <Stack gap={10}>
        <ReportsDetailGraphicConfigurationValueHeader
          label={t('page-reports:measurement') + ' 1'}
          icon={<FontAwesomeIcon icon={faArrowDownLong} />}
        />

        <VSelect
          data-testid="input-funnel-second-measure"
          id="input-funnel-second-measure"
          label={t('page-reports:measurement')}
          searchable={true}
          data={measurementSelectItems}
          value={formik.values?.selectedMultipleMeasure?.[0] || null}
          onChange={(value) => handleOnMeasureChange(value, 0)}
          withinPortal={true}
          withAsterisk={true}
          w="100%"
        />
      </Stack>
      <Stack gap={10}>
        <ReportsDetailGraphicConfigurationValueHeader
          label={t('page-reports:measurement') + ' 2'}
          icon={<FontAwesomeIcon icon={faArrowDownLong} />}
        />

        <VSelect
          data-testid="input-funnel-second-measure"
          id="input-funnel-second-measure"
          label={t('page-reports:measurement')}
          searchable={true}
          disabled={!formik.values.selectedMultipleMeasure?.length || formik.values.selectedMultipleMeasure.length < 1}
          withinPortal={true}
          withAsterisk={true}
          value={formik.values?.selectedMultipleMeasure?.[1] || null}
          onChange={(value) => handleOnMeasureChange(value, 1)}
          data={measurementSelectItems}
          w="100%"
        />
      </Stack>

      <ReportsDetailGraphicConfigurationValueCompare
        compareSelectItems={compareSelectItems}
        disabled={!formik.values.selectedMultipleMeasure?.length || formik.values.selectedMultipleMeasure.length < 2}
      />
    </Stack>
  );
}
