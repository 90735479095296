import { VLazyLoadMultiSelect } from '@vision/ui/components';
import { useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues } from '@vision/ui/interfaces';
import { useLazyGetTagGroupsQuery } from '@vision/ui/services';
import { translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

interface ReportsDetailGraphicCompareTagGroupProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareTagGroup({ required }: ReportsDetailGraphicCompareTagGroupProps) {
  const { t } = useTranslation();
  const accountId = useSelectedAccountId();
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();

  return (
    <ReportsDetailGraphicCompareLayout title={t('tagGroup')}>
      <VLazyLoadMultiSelect
        id="report-compare-tag-group"
        data-testid="input-report-compare-tag-group"
        label={t('tagGroup')}
        useLazyApiQueryFunction={useLazyGetTagGroupsQuery}
        apiRequestParameters={{ nodeId: accountId, sortBy: 'name', sortOrder: 'asc' }}
        multiSelectItemMapper={(items) => {
          return items.map((item) => ({
            value: item.id,
            label: item.name,
          }));
        }}
        searchKey="tag_group_name"
        value={formik.values.selectedCompareCriteria.selectedTagGroupIds}
        onChange={(val) => formik.setFieldValue('selectedCompareCriteria.selectedTagGroupIds', val)}
        error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedTagGroupIds')}
        withAsterisk={required}
      />
    </ReportsDetailGraphicCompareLayout>
  );
}
