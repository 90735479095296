import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ReportDetail,
  ReportPermissions,
  ReportsPermissionsTableRow,
  User,
  UserGroupSimple,
} from '@vision/ui/interfaces';
import { ReportsService } from '@vision/ui/services';
import { createDefaultReportPermission, mapReportPermissions, sortPermissionsByName } from '@vision/ui/utils';

export interface ReportsStateType {
  newReportName: string;
  reportAddModalOpened: boolean;
  reportCopyModalOpened: boolean;
  reportPermissionModalOpened: boolean;
  reportPredefinedGraphicsModalOpened: boolean;
  reportShareModalOpened: boolean;
  selectedReportDetail: ReportDetail;
  selectedReportDetailPermissions: ReportPermissions;
  selectedReportDetailUserGroupPermissions: ReportsPermissionsTableRow[];
  selectedReportDetailUserPermissions: ReportsPermissionsTableRow[];
}

const initialState: ReportsStateType = {
  newReportName: null,
  reportAddModalOpened: false,
  reportCopyModalOpened: false,
  reportPermissionModalOpened: false,
  reportPredefinedGraphicsModalOpened: false,
  reportShareModalOpened: false,
  selectedReportDetail: null,
  selectedReportDetailPermissions: null,
  selectedReportDetailUserGroupPermissions: [],
  selectedReportDetailUserPermissions: [],
};

export const ReportsState = createSlice({
  name: 'ReportsState',
  initialState,
  reducers: {
    updateNewReportNameAction: (state, { payload }: PayloadAction<string>) => {
      state.newReportName = payload;
    },
    openReportPredefinedGraphicsModalAction: (state) => {
      state.reportPredefinedGraphicsModalOpened = true;
    },
    closeReportPredefinedGraphicsModalAction: (state) => {
      state.reportPredefinedGraphicsModalOpened = false;
      state.newReportName = null;
    },
    openReportAddModalAction: (state) => {
      state.reportAddModalOpened = true;
    },
    closeReportAddModalAction: (state) => {
      state.reportAddModalOpened = false;
      state.selectedReportDetail = null;
    },
    openReportCopyModalAction: (state, { payload }: PayloadAction<ReportDetail>) => {
      state.reportCopyModalOpened = true;
      state.selectedReportDetail = payload;
    },
    closeReportCopyModalAction: (state) => {
      state.reportCopyModalOpened = false;
      state.selectedReportDetail = null;
    },
    openReportShareModalAction: (state, { payload }: PayloadAction<ReportDetail>) => {
      state.reportShareModalOpened = true;
      state.selectedReportDetail = payload;
    },
    closeReportShareModalAction: (state) => {
      state.reportShareModalOpened = false;
      state.selectedReportDetail = null;
    },
    openReportPermissionModalAction: (state, { payload }: PayloadAction<ReportDetail>) => {
      state.reportPermissionModalOpened = true;
      state.selectedReportDetail = payload;
    },
    closeReportPermissionModalAction: (state) => {
      state.reportPermissionModalOpened = false;
      state.selectedReportDetail = null;
      state.selectedReportDetailPermissions = null;
      state.selectedReportDetailUserPermissions = [];
      state.selectedReportDetailUserGroupPermissions = [];
    },
    addUserToReportPermissionAction: (state, { payload }: PayloadAction<User>) => {
      const name = payload?.full_name ? payload?.full_name : payload.email;
      const userPermission = createDefaultReportPermission(payload.id, name);
      state.selectedReportDetailUserPermissions.push(userPermission);
      state.selectedReportDetailUserPermissions.sort(sortPermissionsByName);
    },
    addUsersToReportPermissionAction: (state, { payload }: PayloadAction<User[]>) => {
      const usersPermissions: ReportsPermissionsTableRow[] = [];
      payload.forEach((user) => {
        const name = user.name ? `${user.name} ${user.surname}` : user.email;
        const userPermission = createDefaultReportPermission(user.id, name);
        usersPermissions.push(userPermission);
      });
      state.selectedReportDetailUserPermissions.push(...usersPermissions);
      state.selectedReportDetailUserPermissions.sort(sortPermissionsByName);
    },
    updateUserReportPermissionAction: (state, { payload }: PayloadAction<ReportsPermissionsTableRow>) => {
      const index = state.selectedReportDetailUserPermissions.findIndex((item) => item.id === payload.id);
      state.selectedReportDetailUserPermissions[index] = payload;
    },
    deleteUserFromReportPermissionAction: (state, { payload }: PayloadAction<string>) => {
      state.selectedReportDetailUserPermissions = state.selectedReportDetailUserPermissions.filter(
        (item) => item.id !== payload,
      );
    },
    addUserGroupToReportPermissionAction: (state, { payload }: PayloadAction<UserGroupSimple>) => {
      const userGroupPermission = createDefaultReportPermission(payload.id, payload.name);
      state.selectedReportDetailUserGroupPermissions.push(userGroupPermission);
      state.selectedReportDetailUserGroupPermissions.sort(sortPermissionsByName);
    },
    addUserGroupsToReportPermissionAction: (state, { payload }: PayloadAction<UserGroupSimple[]>) => {
      const userGroupsPermissions: ReportsPermissionsTableRow[] = [];
      payload.forEach((userGroup) => {
        const userGroupPermission = createDefaultReportPermission(userGroup.id, userGroup.name);
        userGroupsPermissions.push(userGroupPermission);
      });
      state.selectedReportDetailUserGroupPermissions.push(...userGroupsPermissions);
      state.selectedReportDetailUserGroupPermissions.sort(sortPermissionsByName);
    },
    updateUserGroupReportPermissionAction: (state, { payload }: PayloadAction<ReportsPermissionsTableRow>) => {
      const index = state.selectedReportDetailUserGroupPermissions.findIndex((item) => item.id === payload.id);
      state.selectedReportDetailUserGroupPermissions[index] = payload;
    },
    deleteUserGroupFromReportPermissionAction: (state, { payload }: PayloadAction<string>) => {
      state.selectedReportDetailUserGroupPermissions = state.selectedReportDetailUserGroupPermissions.filter(
        (item) => item.id !== payload,
      );
    },
    clearAllReportPermissionsAction: (state) => {
      state.selectedReportDetailUserPermissions = [];
      state.selectedReportDetailUserGroupPermissions = [];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(ReportsService.endpoints.getReportPermissions.matchFulfilled, (state, { payload }) => {
      state.selectedReportDetailPermissions = payload.data;

      state.selectedReportDetailUserPermissions = payload.data.user_object_permissions.map((item) =>
        mapReportPermissions(item.user.id, item.user.name || item.user.email, item.permissions),
      );
      state.selectedReportDetailUserGroupPermissions = payload.data.user_group_object_permissions.map((item) =>
        mapReportPermissions(item.user_group.id, item.user_group.name, item.permissions),
      );
    });
  },
});

export const {
  updateNewReportNameAction,
  addUserGroupToReportPermissionAction,
  addUserGroupsToReportPermissionAction,
  addUserToReportPermissionAction,
  addUsersToReportPermissionAction,
  clearAllReportPermissionsAction,
  closeReportAddModalAction,
  closeReportCopyModalAction,
  closeReportPermissionModalAction,
  closeReportPredefinedGraphicsModalAction,
  closeReportShareModalAction,
  deleteUserFromReportPermissionAction,
  deleteUserGroupFromReportPermissionAction,
  openReportAddModalAction,
  openReportCopyModalAction,
  openReportPermissionModalAction,
  openReportPredefinedGraphicsModalAction,
  openReportShareModalAction,
  updateUserGroupReportPermissionAction,
  updateUserReportPermissionAction,
} = ReportsState.actions;
