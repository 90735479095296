import loadable from '@loadable/component';
import { Box, Loader } from '@mantine/core';
import { useResizeObserver } from '@mantine/hooks';
import { ReactNode, Suspense } from 'react';
import type { Layout } from 'react-grid-layout';
import classes from './VGridLayout.module.scss';

// Dynamically import GridLayout and CSS
const GridLayout = loadable.lib(() =>
  Promise.all([
    import('react-grid-layout'),
    import('react-grid-layout/css/styles.css'),
    import('react-resizable/css/styles.css'),
  ]).then(([gridModule]) => gridModule),
);

export interface VGridLayoutProps {
  children: ReactNode;
  cols?: number;
  rowHeight?: number;
  margin?: [number, number];
  isDraggable?: boolean;
  isResizable?: boolean;
  onLayoutChange?: (layout: Layout[]) => void;
  onResizeStart?: (layout: Layout[], oldItem: Layout, newItem: Layout) => void;
  onResizeStop?: (layout: Layout[], oldItem: Layout, newItem: Layout) => void;
  draggableCancel?: string;
  resizeHandle?: ReactNode;
  className?: string;
}

function LoadingFallback() {
  return (
    <Box className={classes.gridLayout} style={{ justifyContent: 'center', alignItems: 'center' }}>
      <Loader size="sm" />
    </Box>
  );
}

export function VGridLayout({
  children,
  cols = 12,
  rowHeight = 30,
  margin = [10, 10],
  isDraggable = true,
  isResizable = true,
  onLayoutChange,
  onResizeStart,
  onResizeStop,
  draggableCancel,
  resizeHandle,
  className,
}: VGridLayoutProps) {
  const [ref, rect] = useResizeObserver();

  return (
    <div className={classes.gridLayout} ref={ref}>
      <Suspense fallback={<LoadingFallback />}>
        <GridLayout>
          {({ default: RGLayout }) => (
            <RGLayout
              width={rect.width}
              rowHeight={rowHeight}
              cols={cols}
              margin={margin}
              isDraggable={isDraggable}
              isResizable={isResizable}
              onLayoutChange={onLayoutChange}
              onResizeStart={onResizeStart}
              onResizeStop={onResizeStop}
              draggableCancel={draggableCancel}
              resizeHandle={resizeHandle}
              className={className}
            >
              {children}
            </RGLayout>
          )}
        </GridLayout>
      </Suspense>
    </div>
  );
}
