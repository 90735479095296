import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SortableList,
  Workflow,
  WorkflowCondition,
  WorkflowConditionRow,
  WorkflowSettings,
  WorkflowTask,
  WorkflowTaskCollectionRuleContainer,
} from '@vision/ui/interfaces';
import {
  WorkflowConditionType,
  WorkflowDisplayMode,
  WorkflowEventType,
  WorkflowTaskCampaignType,
  WorkflowType,
} from '@vision/ui/enums';
import { WorkflowsService } from '@vision/ui/services';
import { omit, uuid } from '@vision/ui/utils';
import { showErrorNotification } from '@vision/ui/components';
import i18n from '@vision/ui/i18n';

export interface WorkflowsStateType {
  addConditionPanelOpened: boolean;
  addTaskPanelOpened: boolean;
  addFilterPanelOpened: boolean;
  addWorkflowModalOpened: boolean;
  currentNode: string;
  editingCondition: WorkflowConditionRow;
  editingTask: WorkflowTask;
  editingFilter: WorkflowTaskCollectionRuleContainer;
  managingWorkflow: Workflow;
  displayMode: WorkflowDisplayMode;
  workflows: Workflow[];
}

const initialState: WorkflowsStateType = {
  addConditionPanelOpened: false,
  addFilterPanelOpened: false,
  addTaskPanelOpened: false,
  addWorkflowModalOpened: false,
  currentNode: null,
  editingCondition: null,
  editingFilter: null,
  editingTask: null,
  managingWorkflow: null,
  displayMode: null,
  workflows: null,
};

export const WorkflowsState = createSlice({
  name: 'WorkflowsState',
  initialState,
  reducers: {
    initializeWorkflow: (
      state,
      action: PayloadAction<{
        accountId: string;
        selectedCampaignId: string;
        selectedCampaignType: WorkflowTaskCampaignType;
        selectedWorkflowId: string;
        name: string;
        event_type: WorkflowEventType;
        type: WorkflowType;
      }>,
    ) => {
      const {
        payload: { accountId, selectedCampaignId, selectedCampaignType, selectedWorkflowId, name, event_type, type },
      } = action;

      const isTypeOrEventTypeChanged =
        !!state.managingWorkflow &&
        (state.managingWorkflow.type !== type || state.managingWorkflow.event_type !== event_type);

      const workflow: Workflow = {
        id: state.managingWorkflow?.id || uuid(),
        account_id: accountId,
        event_type,
        name,
        selectedCampaignId,
        selectedCampaignType,
        selectedWorkflowId,
        type,
      };

      if (!state.managingWorkflow || isTypeOrEventTypeChanged) {
        workflow.conditions = { or: { conditions: [] } };
        workflow.tasks = [];
        workflow.settings = null;
      } else {
        workflow.conditions = state.managingWorkflow.conditions;
        workflow.tasks = state.managingWorkflow.tasks;
        workflow.settings = state.managingWorkflow.settings;
      }

      if (
        event_type === WorkflowEventType.WORKFLOW_COMPLETED_EVENT ||
        (type == WorkflowType.SCHEDULED && !state.managingWorkflow)
      ) {
        state.addConditionPanelOpened = true;
      }

      state.managingWorkflow = workflow;
    },
    resetManagingWorkflow: (state) => {
      state.managingWorkflow = null;
      state.displayMode = null;
    },
    setViewingWorkflow: (state, action: PayloadAction<{ workflow: Workflow }>) => {
      const { workflow } = action.payload;

      if (workflow) {
        state.displayMode = WorkflowDisplayMode.PREVIEW;
        state.managingWorkflow = workflow;
      } else {
        state.displayMode = WorkflowDisplayMode.CREATE;
        state.managingWorkflow = null;
      }
    },
    openAddWorkflowModal: (state) => {
      state.addWorkflowModalOpened = true;
    },
    closeAddWorkflowModal: (state) => {
      state.addWorkflowModalOpened = false;
    },
    openAddConditionPanel: (state, action: PayloadAction<{ appendTo?: string }>) => {
      const {
        payload: { appendTo },
      } = action;

      state.editingCondition = null;
      state.editingTask = null;
      state.currentNode = appendTo;
      state.addTaskPanelOpened = false;
      state.addFilterPanelOpened = false;
      state.addConditionPanelOpened = true;
    },
    closeAddConditionPanel: (state) => {
      state.addConditionPanelOpened = false;
    },
    openAddTaskPanel: (state) => {
      state.editingTask = null;
      state.editingCondition = null;
      state.currentNode = null;
      state.addConditionPanelOpened = false;
      state.addFilterPanelOpened = false;
      state.addTaskPanelOpened = true;
    },
    closeAddTaskPanel: (state) => {
      state.addTaskPanelOpened = false;
    },
    openAddFilterPanel: (state, action: PayloadAction<{ task: WorkflowTask; appendTo?: string }>) => {
      const {
        payload: { appendTo, task },
      } = action;

      state.editingTask = task;
      state.editingCondition = null;
      state.currentNode = appendTo;
      state.editingFilter = null;
      state.addConditionPanelOpened = false;
      state.addTaskPanelOpened = false;
      state.addFilterPanelOpened = true;
    },
    closeAddFilterPanel: (state) => {
      state.addFilterPanelOpened = false;
    },
    saveCondition: (
      state,
      action: PayloadAction<{
        conditions: WorkflowCondition[];
        conditionType: WorkflowConditionType;
        closeAddConditionPanel?: boolean;
        replaceConditions?: boolean;
      }>,
    ) => {
      const {
        payload: { conditions, conditionType, closeAddConditionPanel = true, replaceConditions = false },
      } = action;

      const conditionRow: WorkflowConditionRow[] = [
        {
          id: state.editingCondition?.id || uuid(),
          conditionType: conditionType,
          and: { conditions },
        },
      ];

      const targetNode = state.managingWorkflow.conditions.or.conditions.find((og) => og.id === state.currentNode);

      if (targetNode) {
        if (state.editingCondition) {
          const targetCondition = targetNode.and.conditions.find((c) => c.id === state.editingCondition.id);
          targetNode.and.conditions.splice(targetNode.and.conditions.indexOf(targetCondition), 1, ...conditionRow);
        } else {
          targetNode.and.conditions.push(...conditionRow);
        }
      } else {
        if (replaceConditions) state.managingWorkflow.conditions.or.conditions = [];

        state.managingWorkflow.conditions.or.conditions.push({
          id: uuid(),
          and: { id: uuid(), conditions: conditionRow },
        });
      }

      state.currentNode = null;
      state.editingCondition = null;
      if (closeAddConditionPanel) state.addConditionPanelOpened = false;
    },
    editCondition: (state, action: PayloadAction<{ parentId: string; condition: WorkflowConditionRow }>) => {
      const {
        payload: { parentId, condition },
      } = action;

      state.editingTask = null;
      state.editingCondition = condition;
      state.currentNode = parentId;
      state.addTaskPanelOpened = false;
      state.addConditionPanelOpened = true;
    },
    removeCondition: (state, action: PayloadAction<{ parentId: string; id: string }>) => {
      const {
        payload: { parentId, id },
      } = action;

      const parentNode = state.managingWorkflow.conditions.or.conditions.find((og) => og.id === parentId);
      const targetNode = parentNode.and.conditions.find((c) => c.id === id);

      parentNode.and.conditions.splice(parentNode.and.conditions.indexOf(targetNode), 1);

      if (!targetNode.and.conditions.length) {
        parentNode.and.conditions.splice(parentNode.and.conditions.indexOf(targetNode), 1);
      }

      if (!parentNode.and.conditions.length) {
        state.managingWorkflow.conditions.or.conditions.splice(
          state.managingWorkflow.conditions.or.conditions.indexOf(parentNode),
          1,
        );
      }
    },
    saveTask: (
      state,
      action: PayloadAction<{ task: WorkflowTask; closeAddTaskPanel?: boolean; replaceTasks?: boolean }>,
    ) => {
      const {
        payload: { task, closeAddTaskPanel = true, replaceTasks: replaceTasks = false },
      } = action;
      task.key = state.editingTask?.key || uuid();

      if (state.editingTask) {
        const targetTask = state.managingWorkflow.tasks.find((t) => t.key === state.editingTask.key);
        const existingCollections = targetTask.action.collections;

        task.action.collections = existingCollections;

        Object.assign(targetTask, task);
      } else {
        if (replaceTasks) state.managingWorkflow.tasks = [];

        const existingTaskNames = state.managingWorkflow.tasks.map((t) => t.name);
        const taskName = task.name;

        let taskNameCount = 1;

        while (existingTaskNames.includes(task.name)) {
          taskNameCount++;
          task.name = `${taskName} (${taskNameCount})`;
        }

        state.managingWorkflow.tasks.push(task);
      }

      state.editingTask = null;

      if (closeAddTaskPanel) state.addTaskPanelOpened = false;
    },
    editTask: (state, action: PayloadAction<{ task: WorkflowTask }>) => {
      const {
        payload: { task },
      } = action;

      state.editingCondition = null;
      state.currentNode = null;
      state.editingTask = task;
      state.addFilterPanelOpened = false;
      state.editingFilter = null;
      state.addTaskPanelOpened = true;
      state.addConditionPanelOpened = false;
    },
    removeTask: (state, action: PayloadAction<{ key: string }>) => {
      const targetTaskKey = action.payload.key;
      const targetTask = state.managingWorkflow.tasks.find((t) => t.key === targetTaskKey);

      const usingTask = state.managingWorkflow.tasks.find(
        (task) => task.action && task.action.source === targetTaskKey,
      );

      if (usingTask) {
        showErrorNotification({
          message: i18n.t('workflows:workflowEditor.taskInUseAsSource', {
            targetTask: { name: targetTask.name },
            usingTask: { name: usingTask.name },
          }),
        });
        return;
      }

      state.managingWorkflow.tasks.splice(state.managingWorkflow.tasks.indexOf(targetTask), 1);
    },
    renameTask: (state, action: PayloadAction<{ key: string; name: string }>) => {
      const {
        payload: { key, name },
      } = action;

      const targetTask = state.managingWorkflow.tasks.find((t) => t.key === key);

      targetTask.name = name;
    },
    saveFilter: (
      state,
      action: PayloadAction<{ closeAddFilterPanel?: boolean; filter: WorkflowTaskCollectionRuleContainer }>,
    ) => {
      const {
        payload: { closeAddFilterPanel = true, filter },
      } = action;
      filter.id = state.editingFilter?.id || uuid();

      if (state.editingFilter) {
        const targetTask = state.managingWorkflow.tasks.find((t) => t.key === state.editingTask.key);
        const targetCollection = targetTask.action.collections.find((c) => c.id === state.currentNode);
        const targetFilter = targetCollection.rules.find((f) => f.id === state.editingFilter.id);

        Object.assign(targetFilter, filter);
      } else {
        if (state.currentNode) {
          const targetCollection = state.editingTask.action.collections.find((c) => c.id === state.currentNode);
          targetCollection.rules.push(filter);
        } else {
          state.editingTask.action.collections.push({
            id: uuid(),
            rules: [filter],
          });
        }

        const targetTask = state.managingWorkflow.tasks.find((t) => t.key === state.editingTask.key);
        Object.assign(targetTask, state.editingTask);
      }

      state.currentNode = null;
      state.editingFilter = null;

      if (closeAddFilterPanel) state.addFilterPanelOpened = false;
    },
    editFilter: (
      state,
      action: PayloadAction<{ task: WorkflowTask; collectionId: string; filter: WorkflowTaskCollectionRuleContainer }>,
    ) => {
      const {
        payload: { collectionId, filter, task },
      } = action;

      state.editingTask = task;
      state.editingCondition = null;
      state.editingFilter = filter;
      state.currentNode = collectionId;
      state.addConditionPanelOpened = false;
      state.addTaskPanelOpened = false;
      state.addFilterPanelOpened = true;
    },
    removeFilter: (state, action: PayloadAction<{ taskKey: string; collectionId: string; ruleId: string }>) => {
      const {
        payload: { taskKey, collectionId, ruleId },
      } = action;

      const targetTask = state.managingWorkflow.tasks.find((t) => t.key === taskKey);
      const targetCollectionIndex = targetTask.action.collections.findIndex((c) => c.id === collectionId);
      const targetCollection = targetTask.action.collections[targetCollectionIndex];
      const targetFilterIndex = targetCollection.rules.findIndex((f) => f.id === ruleId);

      targetCollection.rules.splice(targetFilterIndex, 1);

      if (targetCollection.rules.length === 0) {
        targetTask.action.collections.splice(targetCollectionIndex, 1);
      }
    },
    saveSettings: (state, action: PayloadAction<{ settings: WorkflowSettings }>) => {
      state.managingWorkflow.settings = action.payload.settings;
      state.addConditionPanelOpened = false;
    },
    editSettings: (state) => {
      state.editingTask = null;
      state.editingFilter = null;
      state.editingCondition = null;
      state.currentNode = null;
      state.addTaskPanelOpened = false;
      state.addConditionPanelOpened = true;
    },
    onWorkflowPublished: (state) => {
      state.addConditionPanelOpened = false;
      state.addTaskPanelOpened = false;
      state.addWorkflowModalOpened = false;
      state.currentNode = null;
      state.displayMode = null;
      state.editingCondition = null;
      state.editingTask = null;
      state.managingWorkflow = null;
    },
    resetManagingContent: (state) => {
      state.addConditionPanelOpened = false;
      state.addTaskPanelOpened = false;
      state.addFilterPanelOpened = false;
      state.currentNode = null;
      state.editingCondition = null;
      state.editingFilter = null;
      state.editingTask = null;
    },
    moveCondition: (state, action: PayloadAction<{ id: string; sourceGroupId: string; targetGroupId: string }>) => {
      const {
        payload: { id, sourceGroupId, targetGroupId },
      } = action;

      if (sourceGroupId !== targetGroupId) {
        const sourceGroup = state.managingWorkflow.conditions.or.conditions.find((g) => g.and.id === sourceGroupId);
        const targetGroup = state.managingWorkflow.conditions.or.conditions.find((g) => g.and.id === targetGroupId);

        const condition = sourceGroup.and.conditions.find((c) => c.id === id);

        sourceGroup.and.conditions.splice(sourceGroup.and.conditions.indexOf(condition), 1);

        if (sourceGroup.and.conditions.length === 0) {
          state.managingWorkflow.conditions.or.conditions.splice(
            state.managingWorkflow.conditions.or.conditions.indexOf(sourceGroup),
            1,
          );
        }

        targetGroup.and.conditions.push(condition);
      }
    },
    moveFilter: (
      state,
      action: PayloadAction<{ taskKey: string; id: string; sourceGroupId: string; targetGroupId: string }>,
    ) => {
      const {
        payload: { taskKey, id, sourceGroupId, targetGroupId },
      } = action;

      if (sourceGroupId !== targetGroupId) {
        const sourceTask = state.managingWorkflow.tasks.find((t) => t.key === taskKey);

        const sourceCollection = sourceTask.action.collections.find((c) => c.id === sourceGroupId);
        const targetCollection = sourceTask.action.collections.find((c) => c.id === targetGroupId);

        const rule = sourceCollection.rules.find((c) => c.id === id);

        sourceCollection.rules.splice(sourceCollection.rules.indexOf(rule), 1);

        if (sourceCollection.rules.length === 0) {
          sourceTask.action.collections.splice(sourceTask.action.collections.indexOf(sourceCollection), 1);
        }

        targetCollection.rules.push(rule);
      }
    },
    reorderTasks: (state, action: PayloadAction<{ tasks: SortableList<WorkflowTask> }>) => {
      state.managingWorkflow.tasks = action.payload.tasks.map((task) => omit(task, 'sortId'));
    },

    setDisplayMode: (state, action: PayloadAction<WorkflowDisplayMode>) => {
      state.displayMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(WorkflowsService.endpoints.workflow.matchFulfilled, (state, action) => {
      state.managingWorkflow = action.payload.data;
    });
    builder.addMatcher(WorkflowsService.endpoints.workflow.matchRejected, () => {
      setViewingWorkflow({ workflow: null });
    });
  },
});

export const {
  closeAddConditionPanel,
  closeAddTaskPanel,
  closeAddWorkflowModal,
  editCondition,
  editFilter,
  editSettings,
  editTask,
  initializeWorkflow,
  moveCondition,
  moveFilter,
  onWorkflowPublished,
  openAddConditionPanel,
  openAddFilterPanel,
  openAddTaskPanel,
  openAddWorkflowModal,
  removeCondition,
  removeFilter,
  removeTask,
  renameTask,
  reorderTasks,
  resetManagingContent,
  resetManagingWorkflow,
  saveCondition,
  saveFilter,
  saveSettings,
  saveTask,
  setDisplayMode,
  setViewingWorkflow,
} = WorkflowsState.actions;
