import React from 'react';

interface CustomIconWordCloudChart1Props extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}

export function CustomIconWordCloudChart1({ height, width, active, ...props }: CustomIconWordCloudChart1Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7877 10.5786C14.4792 10.5786 13.4185 9.51788 13.4185 8.2094C13.4185 6.90091 14.4792 5.84018 15.7877 5.84018C17.0962 5.84018 18.1569 6.90091 18.1569 8.2094C18.1569 9.51788 17.0962 10.5786 15.7877 10.5786Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.206 12.4736C5.85073 12.4736 3.94141 10.5643 3.94141 8.20904C3.94141 5.85377 5.85073 3.94445 8.206 3.94445C10.5613 3.94445 12.4706 5.85377 12.4706 8.20904C12.4706 10.5643 10.5613 12.4736 8.206 12.4736Z"
        fill={active ? '#0066E4' : '#AAAFC9'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4188 14.3691C12.372 14.3691 11.5234 13.5206 11.5234 12.4738C11.5234 11.427 12.372 10.5784 13.4188 10.5784C14.4656 10.5784 15.3142 11.427 15.3142 12.4738C15.3142 13.5206 14.4656 14.3691 13.4188 14.3691Z"
        fill={active ? '#004AA4' : '#AAAFC9'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.15382 20.0554C7.32194 20.0554 5.83691 18.5704 5.83691 16.7385C5.83691 14.9066 7.32194 13.4216 9.15382 13.4216C10.9857 13.4216 12.4707 14.9066 12.4707 16.7385C12.4707 18.5704 10.9857 20.0554 9.15382 20.0554Z"
        fill={active ? '#499BFF' : '#AAAFC9'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.84 18.1599C14.0549 18.1599 13.4185 17.5235 13.4185 16.7384C13.4185 15.9533 14.0549 15.3169 14.84 15.3169C15.6251 15.3169 16.2615 15.9533 16.2615 16.7384C16.2615 17.5235 15.6251 18.1599 14.84 18.1599Z"
        fill={active ? '#0066E4' : '#AAAFC9'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.88909 14.3691C4.3657 14.3691 3.94141 13.9448 3.94141 13.4215C3.94141 12.8981 4.3657 12.4738 4.88909 12.4738C5.41249 12.4738 5.83678 12.8981 5.83678 13.4215C5.83678 13.9448 5.41249 14.3691 4.88909 14.3691Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6309 15.3169C17.3225 15.3169 16.2617 14.2562 16.2617 12.9477C16.2617 11.6392 17.3225 10.5785 18.6309 10.5785C19.9394 10.5785 21.0002 11.6392 21.0002 12.9477C21.0002 14.2562 19.9394 15.3169 18.6309 15.3169Z"
        fill={active ? '#99C7FF' : '#AAAFC9'}
      />
    </svg>
  );
}
