import { VLazyLoadMultiSelect } from '@vision/ui/components';
import { useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues } from '@vision/ui/interfaces';
import { useLazyFetchIntelligentTagsWithPaginationQuery } from '@vision/ui/services';
import { translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

interface ReportsDetailGraphicCompareTagIntelligentProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareTagIntelligent({
  required,
}: ReportsDetailGraphicCompareTagIntelligentProps) {
  const { t } = useTranslation();
  const accountId = useSelectedAccountId();
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();

  return (
    <ReportsDetailGraphicCompareLayout title={t('intelligentTags')}>
      <VLazyLoadMultiSelect
        id="report-compare-intelligent-tags"
        data-testid="input-report-compare-intelligent-tags"
        label={t('intelligentTags')}
        useLazyApiQueryFunction={useLazyFetchIntelligentTagsWithPaginationQuery}
        apiRequestParameters={{
          nodeId: accountId,
        }}
        multiSelectItemMapper={(items) =>
          items.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        }
        searchKey="query"
        value={formik.values.selectedCompareCriteria.selectedIntelligentTagIds}
        onChange={(e) => formik.setFieldValue('selectedCompareCriteria.selectedIntelligentTagIds', e)}
        error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedIntelligentTagIds')}
        withAsterisk={required}
        withSelectAll={false}
      />
    </ReportsDetailGraphicCompareLayout>
  );
}
