import { PISANO_COLORS } from '@vision/theme';
import { ReportChartConfiguration } from '@vision/ui/interfaces';
import { t } from 'i18next';
import { object, string } from 'yup';
import { DefaultReportChartThemeFont, GRAPHIC_TITLE_MAX_CHAR_COUNT } from '../../default-chart.config';
import { EXCardChart1Measures } from './ex-card-chart1.measures';

export const EXCardChart1DefaultConfiguration: ReportChartConfiguration = {
  validationSchema: object().shape({
    selectedDate: object().shape({
      fromDate: string().required('validation:rules.generic.required'),
      toDate: string().required('validation:rules.generic.required'),
      dateRange: string().optional(),
    }),
    selectedMeasure: string().required('validation:rules.generic.required'),
    selectedTheme: object().shape({
      title: object().shape({
        label: string()
          .max(
            GRAPHIC_TITLE_MAX_CHAR_COUNT,
            t('validation:rules.generic.max', {
              count: GRAPHIC_TITLE_MAX_CHAR_COUNT,
            }),
          )
          .required('validation:rules.reports.selectedTheme.title.label.required'),
      }),
    }),
  }),
  measures: EXCardChart1Measures,
  compareRendererComponent: null,
  compareMultiple: true,
  hideCompare: true,
  customize: {
    active: (values) => !!values.selectedMeasure,
    transactionalDate: {
      active: false,
    },
  },
  defaultTheme: {
    active: (values) => !!values.selectedMeasure,
    title: {
      ...DefaultReportChartThemeFont,
      label: '',
    },
    dataLabels: {
      ...DefaultReportChartThemeFont,
      active: false,
      dataLabelColorize: {
        positive: {
          ...DefaultReportChartThemeFont,
          fontSize: 34,
          fontColor: PISANO_COLORS.blue[5],
          backgroundColor: PISANO_COLORS.blue[0],
        },
        negative: {
          ...DefaultReportChartThemeFont,
          fontSize: 34,
          fontColor: PISANO_COLORS.red[5],
          backgroundColor: PISANO_COLORS.red[0],
        },
      },
    },
  },
};
