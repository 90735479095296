import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@vision/ui/store';

export const leoStatusSelector = createSelector(
  (state: RootState) => state.LeoState,
  ({ analysis, analysisType, analyzeHistory, buttonPopover, currentAnalyzeProgress, isHistoryVisible, leoStatus }) => ({
    analysis,
    analysisType,
    analyzeHistory,
    buttonPopover,
    currentAnalyzeProgress,
    isHistoryVisible,
    leoStatus,
  }),
);

export const leoStageSelector = createSelector(
  (state: RootState) => state.LeoState,
  ({ analysis, analysisType, currentAnalyzeProgress, isHistoryVisible, credits }) => ({
    credits,
    analysis,
    analysisType,
    currentAnalyzeProgress,
    isHistoryVisible,
  }),
);

export const leoAnalysisSelector = createSelector(
  (state: RootState) => state.LeoState,
  ({ analysis, analysisType }) => ({
    analysis,
    analysisType,
  }),
);

export const leoHistorySelector = createSelector(
  (state: RootState) => state.LeoState,
  ({ analyzeHistory, isHistoryVisible, historyType, leoStatus, historyLoading }) => ({
    leoStatus,
    historyType,
    analyzeHistory,
    isHistoryVisible,
    historyLoading,
  }),
);

export const leoCreditsSelector = createSelector(
  (state: RootState) => state.LeoState,
  ({ analysis, credits, currentAnalyzeProgress, leoStatus }) => ({
    analysis,
    credits,
    currentAnalyzeProgress,
    leoStatus,
  }),
);

export const leoPromptSelector = createSelector(
  (state: RootState) => state.LeoState,
  ({ confirmLeavePrompt }) => ({
    confirmLeavePrompt,
  }),
);

export const leoHistoryItemSelector = createSelector(
  (state: RootState) => state.LeoState,
  ({ analysis, analysisType, currentAnalyzeProgress, historyType }) => ({
    analysis,
    analysisType,
    currentAnalyzeProgress,
    historyType,
  }),
);

export const leoAnalysisTypeSelector = createSelector(
  (state: RootState) => state.LeoState,
  ({ analysisType }) => ({
    analysisType,
  }),
);

export const leoAnalyzeEntitiesSelector = createSelector(
  (state: RootState) => state.LeoState,
  ({ flows, dashboards }) => ({
    dashboards,
    flows,
  }),
);

export const leoCurrentAnalysisProgressSelector = createSelector(
  (state: RootState) => state.LeoState,
  ({ currentAnalyzeProgress }) => ({
    currentAnalyzeProgress,
  }),
);
