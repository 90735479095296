import { ReportChartConfiguration } from '@vision/ui/interfaces';
import { t } from 'i18next';
import { array, object, string } from 'yup';
import { FunnelChart1ValueComponent } from '../../components';
import {
  DefaultReportChartThemeFont,
  DefaultReportChartThemeSeries,
  GRAPHIC_TITLE_MAX_CHAR_COUNT,
} from '../default-chart.config';

export const FunnelChart1DefaultConfiguration: ReportChartConfiguration = {
  validationSchema: object().shape({
    selectedDate: object().shape({
      fromDate: string().required('validation:rules.generic.required'),
      toDate: string().required('validation:rules.generic.required'),
      dateRange: string().optional(),
    }),
    selectedMeasure: string().required('validation:rules.generic.required'),
    selectedTheme: object().shape({
      title: object().shape({
        label: string()
          .max(
            GRAPHIC_TITLE_MAX_CHAR_COUNT,
            t('validation:rules.generic.max', {
              count: GRAPHIC_TITLE_MAX_CHAR_COUNT,
            }),
          )
          .required('validation:rules.reports.selectedTheme.title.label.required'),
      }),
    }),
    selectedCustomize: object().shape({
      campaignType: string().required('validation:rules.generic.required'),
    }),
    selectedFilters: object().shape({
      selectedChannelIds: array().of(string()).min(1, 'validation:rules.generic.required'),
    }),
  }),
  measures: undefined,
  compareRendererComponent: null,
  chartValueComponent: FunnelChart1ValueComponent,
  hideCompare: true,
  customize: {
    hide: true,
    campaignType: null,
  },
  defaultTheme: {
    active: (values) => !!values.selectedCustomize.campaignType && values.selectedFilters.selectedChannelIds.length > 0,
    title: {
      ...DefaultReportChartThemeFont,
      label: '',
    },
    dataLabels: {
      ...DefaultReportChartThemeFont,
      active: false,
      position: 'outer',
    },
    series: {
      ...DefaultReportChartThemeSeries,
    },
    extraSettings: {
      orient: 'vertical',
    },
  },
  filters() {
    return [];
  },
  hideFilters: true,
  defaultMeasure: 'funnel-metrics',
};
