import { Stack, Text } from '@mantine/core';
import { If } from '../If';

interface VSelectOptionWithDescriptionProps extends React.ComponentPropsWithoutRef<'span'> {
  label: string;
  value: string;
  description: string;
  title?: string;
}

export function VSelectOptionWithDescription({ label, description, title }: VSelectOptionWithDescriptionProps) {
  return (
    <Stack gap={0}>
      <Text component="span" lineClamp={2} title={label}>
        {label}
      </Text>
      <If value={!!description}>
        <Text component="span" size="xs" c="dimmed" lineClamp={1} title={title || description}>
          {description}
        </Text>
      </If>
    </Stack>
  );
}
