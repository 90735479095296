import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  AccountService,
  AuthService,
  BusinessDetailService,
  CatiCallService,
  CompanyService,
  ContactDetailService,
  ContactPolicyService,
  ContentCategoryService,
  CustomerListService,
  CustomersService,
  DashboardsService,
  DepartmentsService,
  ExDashboardService,
  FeedbackService,
  FileService,
  FlowTemplatesService,
  FlowsService,
  LanguagesService,
  LeoService,
  NodesService,
  NotificationsService,
  ReportWidgetsService,
  ReportWizardService,
  ReportsService,
  SchemaFieldsService,
  SegmentsService,
  SmsProviderService,
  TagService,
  UserGroupsService,
  UserProfileService,
  UsersService,
  VisionResourcesService,
  WorkflowsService,
} from '@vision/ui/services';
import { __DEV__ } from '@vision/ui/utils';
import { persistReducer, persistStore } from 'redux-persist';
import createFilter from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage';
import { StateResetAction } from './actions';
import { ListenerMiddleware } from './middlewares';
import {
  AuthSsoState,
  AuthState,
  AuthStateType,
  ContactsState,
  LanguagesState,
  LeoState,
  NotificationState,
  ReportWidgetsState,
  ReportsDetailGraphicState,
  ReportsState,
  SmsProviderState,
  UploadProgressState,
  WorkflowsState,
} from './states';

const STATES = {
  AuthSsoState,
  AuthState,
  ContactsState,
  LanguagesState,
  LeoState,
  SmsProviderState,
  NotificationState,
  ReportsDetailGraphicState,
  ReportsState,
  ReportWidgetsState,
  UploadProgressState,
  WorkflowsState,
};

const MIDDLEWARES = [
  AccountService.middleware,
  AuthService.middleware,
  BusinessDetailService.middleware,
  CompanyService.middleware,
  ContactDetailService.middleware,
  ContactPolicyService.middleware,
  CustomerListService.middleware,
  ContentCategoryService.middleware,
  CustomersService.middleware,
  DashboardsService.middleware,
  FeedbackService.middleware,
  FlowsService.middleware,
  FlowTemplatesService.middleware,
  LanguagesService.middleware,
  LeoService.middleware,
  NodesService.middleware,
  NotificationsService.middleware,
  ReportsService.middleware,
  ReportWizardService.middleware,
  SchemaFieldsService.middleware,
  SegmentsService.middleware,
  TagService.middleware,
  SmsProviderService.middleware,
  UserGroupsService.middleware,
  UserProfileService.middleware,
  UsersService.middleware,
  VisionResourcesService.middleware,
  WorkflowsService.middleware,
  FileService.middleware,
  CatiCallService.middleware,
  ReportWidgetsService.middleware,
  ExDashboardService.middleware,
  DepartmentsService.middleware,
];

const SERVICES = {
  [AccountService.reducerPath]: AccountService.reducer,
  [AuthService.reducerPath]: AuthService.reducer,
  [BusinessDetailService.reducerPath]: BusinessDetailService.reducer,
  [CompanyService.reducerPath]: CompanyService.reducer,
  [ContactDetailService.reducerPath]: ContactDetailService.reducer,
  [ContactPolicyService.reducerPath]: ContactPolicyService.reducer,
  [ContentCategoryService.reducerPath]: ContentCategoryService.reducer,
  [CustomerListService.reducerPath]: CustomerListService.reducer,
  [CustomersService.reducerPath]: CustomersService.reducer,
  [DashboardsService.reducerPath]: DashboardsService.reducer,
  [FeedbackService.reducerPath]: FeedbackService.reducer,
  [FlowsService.reducerPath]: FlowsService.reducer,
  [FlowTemplatesService.reducerPath]: FlowTemplatesService.reducer,
  [LanguagesService.reducerPath]: LanguagesService.reducer,
  [LeoService.reducerPath]: LeoService.reducer,
  [NodesService.reducerPath]: NodesService.reducer,
  [NotificationsService.reducerPath]: NotificationsService.reducer,
  [ReportsService.reducerPath]: ReportsService.reducer,
  [ReportWizardService.reducerPath]: ReportWizardService.reducer,
  [SchemaFieldsService.reducerPath]: SchemaFieldsService.reducer,
  [SegmentsService.reducerPath]: SegmentsService.reducer,
  [SmsProviderService.reducerPath]: SmsProviderService.reducer,
  [TagService.reducerPath]: TagService.reducer,
  [UserGroupsService.reducerPath]: UserGroupsService.reducer,
  [UserProfileService.reducerPath]: UserProfileService.reducer,
  [UsersService.reducerPath]: UsersService.reducer,
  [VisionResourcesService.reducerPath]: VisionResourcesService.reducer,
  [WorkflowsService.reducerPath]: WorkflowsService.reducer,
  [FileService.reducerPath]: FileService.reducer,
  [CatiCallService.reducerPath]: CatiCallService.reducer,
  [ReportWidgetsService.reducerPath]: ReportWidgetsService.reducer,
  [ExDashboardService.reducerPath]: ExDashboardService.reducer,
  [DepartmentsService.reducerPath]: DepartmentsService.reducer,
};

const REDUCERS = {
  AuthSsoState: AuthSsoState.reducer,
  AuthState: AuthState.reducer,
  ContactsState: ContactsState.reducer,
  LanguagesState: LanguagesState.reducer,
  LeoState: LeoState.reducer,
  SmsProviderState: SmsProviderState.reducer,
  NotificationState: NotificationState.reducer,
  ReportsDetailGraphicState: ReportsDetailGraphicState.reducer,
  ReportsState: ReportsState.reducer,
  ReportWidgetsState: ReportWidgetsState.reducer,
  UploadProgressState: UploadProgressState.reducer,
  WorkflowsState: WorkflowsState.reducer,
};

const COMBINED_REDUCERS = combineReducers({
  ...SERVICES,
  ...REDUCERS,
});

/*
 * Save only 'token' and 'id' to localStorage for user authentication
 */
const FilteredAuthState = createFilter<
  { token: string; id: string },
  AuthStateType,
  ReturnType<typeof COMBINED_REDUCERS>,
  ReturnType<typeof COMBINED_REDUCERS>
>(AuthState.name, ['token', 'id', 'selectedAccountId'] as (keyof AuthStateType)[]);

const persistedReducer = persistReducer(
  {
    key: 'vision',
    version: 1,
    storage,
    whitelist: [AuthState.name],
    transforms: [FilteredAuthState],
  },
  COMBINED_REDUCERS,
);

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === StateResetAction.type) {
    const stateKey = action.payload! as StateKeys;

    return persistedReducer(
      {
        ...state,
        [stateKey]: STATES[stateKey].getInitialState(),
      },
      action,
    );
  }
  return persistedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .prepend(ListenerMiddleware.middleware)
      .concat(MIDDLEWARES),
  devTools: __DEV__,
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = Pick<ReturnType<typeof store.getState>, StateKeys>;
export type StateKeys = keyof typeof STATES;

setupListeners(store.dispatch);
