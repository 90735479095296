import { Stack } from '@mantine/core';
import { VLazyLoadMultiSelect, VLazyLoadSelect, VSelectOptionWithDescription } from '@vision/ui/components';
import { useEmojiContext, useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues } from '@vision/ui/interfaces';
import { useLazyFetchFlowQuestionsWithPaginationQuery } from '@vision/ui/services';
import { getBodyText, translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../../ReportsDetailGraphicCompareComponents';

interface ReportsDetailGraphicMeasureMatrixQuestionProps {
  multiple?: boolean;
}

export function ReportsDetailGraphicMeasureMatrixQuestion({
  multiple = true,
}: ReportsDetailGraphicMeasureMatrixQuestionProps) {
  const { i18n, t } = useTranslation();
  const accountId = useSelectedAccountId();
  const { textWithNativeEmoji } = useEmojiContext();
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();

  return (
    <Stack gap={5}>
      <ReportsDetailGraphicCompareLayout title={t('question')}>
        {multiple ? (
          <VLazyLoadMultiSelect
            id="report-measure-matrix-question"
            data-testid="input-report-measure-matrix-question"
            label={t('question')}
            useLazyApiQueryFunction={useLazyFetchFlowQuestionsWithPaginationQuery}
            apiRequestParameters={{
              nodeId: accountId,
              style: ['matrix'],
              sortBy: 'name',
              sortOrder: 'asc',
            }}
            multiSelectItemMapper={(items) => {
              return items
                .filter((item) => item.body !== null)
                .map((item) => ({
                  value: item.id,
                  label: textWithNativeEmoji(getBodyText(item.body, i18n.language)),
                  description: item?.flow_name,
                }));
            }}
            value={formik.values.selectedFilters.selectedQuestionIds}
            onChange={(e) => formik.setFieldValue('selectedFilters.selectedQuestionIds', e)}
            error={translateErrorMessage(formik, 'selectedFilters.selectedQuestionIds')}
            onBlur={formik.handleBlur}
            withAsterisk={true}
            itemComponent={VSelectOptionWithDescription}
          />
        ) : (
          <VLazyLoadSelect
            data-testid="report-measure-matrix-question-single"
            label={t('question')}
            useLazyApiQueryFunction={useLazyFetchFlowQuestionsWithPaginationQuery}
            apiRequestParameters={{
              nodeId: accountId,
              style: ['matrix'],
              sortBy: 'name',
              sortOrder: 'asc',
            }}
            multiSelectItemMapper={(items) =>
              items
                .filter((item) => item.body !== null)
                .map((item) => ({
                  value: item.id,
                  label: textWithNativeEmoji(getBodyText(item.body, i18n.language)),
                  description: item?.flow_name,
                }))
            }
            searchKey="question_body"
            value={formik.values.selectedFilters.selectedQuestionIds[0] || null}
            onChange={(value) => {
              formik.setFieldValue('selectedFilters.selectedQuestionIds', [value]);
            }}
            error={translateErrorMessage(formik, 'selectedFilters.selectedQuestionIds')}
            onBlur={formik.handleBlur}
            withAsterisk={true}
            itemComponent={VSelectOptionWithDescription}
          />
        )}
      </ReportsDetailGraphicCompareLayout>
    </Stack>
  );
}
