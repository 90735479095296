import { Group, Image, Stack, Title } from '@mantine/core';
import { PropsWithChildren } from 'react';
import classes from './LeoQuestionWrapper.module.scss';
import LeoLogo from '@vision/ui/images/leo/leo-logo.svg';

interface LeoQuestionWrapperProps {
  style?: React.CSSProperties;
  tags?: string[];
  title: string;
}

export function LeoQuestionWrapper({ children, style, tags, title }: PropsWithChildren<LeoQuestionWrapperProps>) {
  return (
    <Group className={classes.leoQuestionWrapper} style={style}>
      <Image className={classes.leoLogo} src={LeoLogo} />
      <Stack className={classes.questionContent}>
        <Title className={classes.title}>{title}</Title>
        <Group className={classes.tags}>
          {tags.map((tag, index) => (
            <span className={classes.tag} key={index}>
              {tag}
            </span>
          ))}
        </Group>

        {children}
      </Stack>
    </Group>
  );
}

function Form({ className, children }: { children: React.ReactNode; className?: string }) {
  return <div className={className}>{children}</div>;
}

LeoQuestionWrapper.Form = Form;
