import { MapSeriesOption } from 'echarts/types/dist/shared';
import { EC, SeriesProps } from '../../ECharts.types';
import { chartLoader, seriesOptionCreator } from '../../ECharts.utils';

export type MapSeriesProps = SeriesProps<MapSeriesOption>;

/**
 * MapSeries component for rendering map visualizations in ECharts
 *
 * @requires MapRegister - Map must be registered before using MapSeries
 * @example
 * ```tsx
 * <MapSeries
 *   map="myMapName"           // Name used in MapRegister
 *   roam={true}              // Enable pan and zoom
 *   selectedMode={false}     // Disable selection
 *   zoom={1.3}              // Initial zoom level
 *   labelLayout={{
 *     hideOverlap: true,    // Hide overlapping labels
 *   }}
 *   nameProperty="name"     // Property to use for region names
 *   label={{
 *     show: true,
 *     fontSize: 11,
 *     fontWeight: 'bold',
 *   }}
 *   data={[                 // Data to visualize
 *     { name: 'Region1', value: 100 },
 *     { name: 'Region2', value: 200 },
 *   ]}
 * />
 * ```
 */
export const MapSeries: EC<MapSeriesProps> = () => null;

MapSeries.optionOf = seriesOptionCreator('map');

MapSeries.loadModule = chartLoader(['MapChart']);
