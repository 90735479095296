import { If, VLazyLoadMultiSelect, VMultiSelect, VSelectOptionWithDescription } from '@vision/ui/components';
import { useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues, SimpleNode } from '@vision/ui/interfaces';
import { useGetNodeTypesQuery, useLazyFetchNodesWithPaginationQuery } from '@vision/ui/services';
import { translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

interface ReportsDetailGraphicCompareUnitProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareUnit({ required }: ReportsDetailGraphicCompareUnitProps) {
  const { t } = useTranslation(['translation', 'page-reports']);
  const accountId = useSelectedAccountId();
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();

  const { data: unitTypes, isFetching } = useGetNodeTypesQuery({
    nodeId: accountId,
    types: ['Unit', 'Account'],
  });

  const unitTypeSelectItems = useMemo(() => {
    return unitTypes?.map((item) => ({
      label: item,
      value: item,
    }));
  }, [unitTypes]);

  const handleDefaultValuesLoaded = (data: SimpleNode[]) => {
    const newIds = formik.values.selectedCompareCriteria.selectedUnitIds.filter((id) =>
      data.some((item) => item.id === id),
    );
    formik.setFieldValue('selectedCompareCriteria.selectedUnitIds', newIds);
  };

  return (
    <ReportsDetailGraphicCompareLayout title={t('page-reports:reportCompareGroupItemType.unit-name')}>
      <VMultiSelect
        id="report-compare-unit-types"
        data-testid="input-report-compare-unit-types"
        label={t('unitTypes')}
        data={unitTypeSelectItems}
        loading={isFetching}
        value={formik.values.selectedCompareCriteria.selectedUnitTypeIds}
        searchable={true}
        clearable={true}
        error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedUnitTypeIds')}
        onChange={(e) => formik.setFieldValue('selectedCompareCriteria.selectedUnitTypeIds', e)}
        withAsterisk={required}
        withSelectAll={true}
      />
      <If value={formik.values.selectedCompareCriteria.selectedUnitTypeIds.length > 0}>
        <VLazyLoadMultiSelect
          id="report-compare-units"
          data-testid="input-report-compare-units"
          label={t('units')}
          useLazyApiQueryFunction={useLazyFetchNodesWithPaginationQuery}
          apiRequestParameters={{
            nodeId: accountId,
            types: formik.values.selectedCompareCriteria.selectedUnitTypeIds,
            includeSelf: formik.values.selectedCompareCriteria.selectedUnitTypeIds.includes('Account'),
            sortBy: 'name',
            sortOrder: 'asc',
          }}
          multiSelectItemMapper={(items) => {
            return items.map((item) => ({
              value: item.id,
              label: item.name,
              description: item?.type,
            }));
          }}
          searchKey="node_name"
          value={formik.values.selectedCompareCriteria.selectedUnitIds}
          onBlur={formik.handleBlur}
          onChange={(e) => formik.setFieldValue('selectedCompareCriteria.selectedUnitIds', e)}
          error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedUnitIds')}
          resetChangeRequestParameters={true}
          withAsterisk={required}
          onDefaultValuesLoaded={handleDefaultValuesLoaded}
          itemComponent={VSelectOptionWithDescription}
        />
      </If>
    </ReportsDetailGraphicCompareLayout>
  );
}
