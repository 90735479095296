import { forwardRef, ReactNode, Ref, ForwardRefExoticComponent } from 'react';
import { Group, Popover, PopoverProps, ScrollArea, Text } from '@mantine/core';
import classes from './LeoPopover.module.scss';
import { If } from '@vision/ui/components';

interface LeoPopoverProps extends Omit<PopoverProps, 'children'> {
  children: ReactNode;
  id?: string;
  width?: number;
}

interface LeoPopoverTargetProps {
  children: ReactNode;
}

interface LeoPopoverDropdownProps {
  actionButtons?: ReactNode;
  content?: string | ReactNode;
  title?: string | ReactNode;
}

interface LeoPopoverComponent extends ForwardRefExoticComponent<LeoPopoverProps> {
  Dropdown: typeof LeoPopoverDropdown;
  Target: typeof LeoPopoverTarget;
}

const LeoPopover = forwardRef<HTMLDivElement, LeoPopoverProps>(
  ({ children, width, position, disabled, ...props }, ref: Ref<HTMLDivElement>) => {
    return (
      <div className={classes.leoPopover} ref={ref}>
        <Popover
          arrowOffset={100}
          arrowSize={12}
          classNames={{ dropdown: classes.dropdown, arrow: classes.arrow }}
          disabled={disabled}
          offset={{ mainAxis: 30, crossAxis: -75 }}
          position={position}
          width={width}
          withArrow={true}
          {...props}
        >
          {children}
        </Popover>
      </div>
    );
  },
) as LeoPopoverComponent;

function LeoPopoverTarget({ children }: LeoPopoverTargetProps) {
  return <Popover.Target>{children}</Popover.Target>;
}

function LeoPopoverDropdown({ title, content, actionButtons }: LeoPopoverDropdownProps) {
  return (
    <Popover.Dropdown className={classes.dropdown}>
      <If value={!!title}>
        <Text className={classes.title}>{title}</Text>
      </If>

      <If value={!!content}>
        <ScrollArea className={classes.content} type="auto" scrollbarSize={3}>
          {content}
        </ScrollArea>
      </If>

      <If value={!!actionButtons}>
        <Group className={classes.footer}>{actionButtons}</Group>
      </If>
    </Popover.Dropdown>
  );
}

LeoPopover.Target = LeoPopoverTarget;
LeoPopover.Dropdown = LeoPopoverDropdown;

export { LeoPopover };
